import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Grid, FormControl, InputAdornment, Input } from '@material-ui/core';

function SizeRange({ unit, value, onChangeSize }) {
    const unitText = useMemo(() => (unit === 'm2' ? '㎡' : '평'), [unit]);
    const [range, setRange] = useState({
        min: value.min || 1,
        max: value.max || 1,
    });

    const [input, setInput] = useState({
        min: 1,
        max: 1,
    });

    const getValue = useCallback(
        (value) => {
            const tempValue = value || 1;
            return unit === 'm2'
                ? (Number(tempValue) * 3.3).toFixed(1)
                : Math.round(Number(tempValue));
        },
        [unit],
    );

    const onChangeRange = useCallback(
        (min, max) => {
            setRange((range) => ({
                min: unit === 'm2' ? Math.round(min / 3.3) : min,
                max: unit === 'm2' ? Math.round(min / 3.3) : max,
            }));

            setInput({ min, max });
            onChangeSize({ min, max });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [unit],
    );

    useEffect(() => {
        setInput({
            min: getValue(range.min),
            max: getValue(range.max),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unit, range]);

    useEffect(() => {
        setRange({
            min: value.min || 1,
            max: value.max || 1,
        });
    }, [value]);

    return (
        <>
            <Grid container>
                <Grid item xs={5}>
                    <FormControl>
                        <Input
                            type="number"
                            fullWidth
                            value={input.min}
                            onChange={(e) => {
                                onChangeRange(e.target.value, range.max);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    {unitText}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <span
                        style={{
                            marginTop: '1rem',
                            display: 'inline-block',
                        }}
                    >
                        ~
                    </span>
                </Grid>
                <Grid item xs={5}>
                    <FormControl>
                        <Input
                            type="number"
                            fullWidth
                            value={input.max}
                            onChange={(e) => {
                                onChangeRange(range.min, e.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    {unitText}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}

export default React.memo(SizeRange);
