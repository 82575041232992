import React, { useState } from 'react';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import {
    Drawer,
    MenuItem,
    MenuList,
    makeStyles,
    Divider,
} from '@material-ui/core';

import SearchBox from './SearchBox';

const Header = styled.header`
    position: relative;
    width: 100%;
    height: 70px;
    background: #000;
`;

const Logo = styled.div``;

const LogoImageWrap = styled.div`
    position: relative;
    display: inline-block;
    background: #000;
    width: 150px;
    height: 70px;
`;

const DeskLogoImage = styled.div`
    position: absolute;
    right: -70px;
    background: url('//www.jeonginpmc.com/img/new_logo.png') no-repeat;
    background-size: auto 70px;
    width: 200px;
    height: 70px;

    @media only screen and (max-width: 767px) {
        display: none;
    }
`;

const MobileLogoImage = styled.div`
    @media only screen and (max-width: 767px) {
        position: absolute;
        right: -32px;
        background: url('//www.jeonginpmc.com/img/new_logo2.png') no-repeat;
        background-size: auto 70px;
        width: 200px;
        height: 70px;
        top: 2px;
    }
`;

const GnbWrap = styled.div`
    position: absolute;
    display: flex;
    right: 10px;
    top: 0;
    bottom: 0;
    font-size: 1.3em;
    margin-right: 2px;
    align-items: center;
    justify-content: center;
    justify-align: center;
`;

const MobileSearchWrap = styled.div`
    display: none;
    @media only screen and (max-width: 767px) {
        position: relative;
        font-size: 14px;
        display: inline-block;
        top: -28px;
        left: -90px;
    }

    @media only screen and (max-width: 400px) {
        position: relative;
        font-size: 14px;
        display: inline-block;
        top: -58px;
        left: 54px;
    }
`;

const DeskSearchWrap = styled.div`
    position: relative;
    font-size: 14px;
    display: inline-block;
    top: -28px;
    left: 75px;
    @media only screen and (max-width: 767px) {
        display: none;
    }

    /* IE10+ */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: -11px;
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));

export default () => {
    const classes = useStyles();
    const [menuState, setMenuState] = useState(false);

    const toggleDrawer = (state) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setMenuState(state);
    };

    return (
        <Header>
            <Logo>
                <LogoImageWrap>
                    <DeskLogoImage
                        onClick={() => {
                            document.location.href = '/map';
                        }}
                    ></DeskLogoImage>
                    <MobileLogoImage
                        onClick={() => {
                            document.location.href = '/map';
                        }}
                    ></MobileLogoImage>
                </LogoImageWrap>
                {/* DeskTop Search UI */}
                <DeskSearchWrap>
                    <SearchBox width={280} padding={'2px 4px'} />
                </DeskSearchWrap>
                {/* Mobile Search UI */}
                <MobileSearchWrap>
                    <SearchBox width={256} padding={'0px'} />
                </MobileSearchWrap>
                <GnbWrap>
                    <MenuIcon onClick={toggleDrawer(true)}></MenuIcon>
                    <Drawer
                        anchor="right"
                        open={menuState}
                        onClose={toggleDrawer(false)}
                    >
                        <div className={classes.root}>
                            <MenuList style={{ width: '200px' }}>
                                <MenuItem>MENU</MenuItem>
                                <Divider
                                    light
                                    style={{ marginBottom: '0.5rem' }}
                                />
                                <a
                                    href="//www.jeonginpmc.com/home.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MenuItem>홈</MenuItem>
                                </a>
                                <a
                                    href="//www.jeonginpmc.com/company01.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MenuItem>회사소개</MenuItem>
                                </a>
                                <a
                                    href="//www.jeonginpmc.com/business.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MenuItem>사업영역</MenuItem>
                                </a>
                                <a
                                    href="//www.jeonginpmc.com/information04.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MenuItem>의뢰하기</MenuItem>
                                </a>
                                <a
                                    href="//www.jeonginpmc.com/bbs/board.php?bo_table=notice"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MenuItem>서비스</MenuItem>
                                </a>
                            </MenuList>
                        </div>
                    </Drawer>
                </GnbWrap>
            </Logo>
            <ul></ul>
        </Header>
    );
};
