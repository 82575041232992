import { locationOptions, themeOptions } from '../lib/filterOptions';

const SET_PRICE_FILTER = 'filter_sales/SET_PRICE_FILTER';
const SET_LOCATION_FILTER = 'filter_sales/SET_LOCATION_FILTER';
const SET_THEME_FILTER = 'filter_sales/SET_THEME_FILTER';
const SET_FAVORITE_FILTER = 'filter_sales/SET_FAVORITE_FILTER';

export const setPriceFilter = (payload) => ({
    type: SET_PRICE_FILTER,
    payload,
});

export const setLocationFilter = (payload) => ({
    type: SET_LOCATION_FILTER,
    payload,
});

export const setThemeFilter = (payload) => ({
    type: SET_THEME_FILTER,
    payload,
});

export const setFavoriteFilter = (payload) => ({
    type: SET_FAVORITE_FILTER,
    payload,
});

const initialState = JSON.parse(
    window.localStorage.getItem('buildings_filter'),
) || {
    price: undefined,
    location: locationOptions.map((o) => o.value),
    theme: themeOptions.map((o) => o.value),
    favorite: false,
};

export default function filterBuilding(state = initialState, action) {
    let newState;
    switch (action.type) {
        case SET_PRICE_FILTER:
            newState = {
                ...state,
                price: action.payload,
            };
            break;
        case SET_LOCATION_FILTER:
            newState = {
                ...state,
                location: action.payload,
            };
            break;
        case SET_THEME_FILTER:
            newState = {
                ...state,
                theme: action.payload,
            };
            break;
        case SET_FAVORITE_FILTER:
            newState = {
                ...state,
                favorite: action.payload,
            };
            break;
        default:
            return state;
    }

    window.localStorage.setItem('buildings_filter', JSON.stringify(newState));
    return { ...newState };
}
