import React from 'react';

import { InfomationWrap } from './tags/InfomationTags';
import Buildings from './Buildings';
import BuildingDetail from './BuilidingDetail';

function Infomation({
    sales,
    hots,
    filter,
    selectedBuilding,
    mobileInfomationnStatus,
    onClickOpenInfomation,
    onClickCloseBuildingDetail,
    onClickBuilding,
}) {
    return (
        <InfomationWrap
            isOpenInMobile={selectedBuilding || mobileInfomationnStatus}
        >
            {selectedBuilding ? (
                <BuildingDetail
                    building={selectedBuilding}
                    onClickCloseBuildingDetail={onClickCloseBuildingDetail}
                />
            ) : (
                <Buildings
                    mobileInfomationnStatus={mobileInfomationnStatus}
                    onClickOpenInfomation={onClickOpenInfomation}
                    onClickBuilding={onClickBuilding}
                    sales={sales}
                    hots={hots}
                    filter={filter}
                />
            )}
        </InfomationWrap>
    );
}

export default Infomation;
