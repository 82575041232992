import { Badge, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { indigo, grey } from '@material-ui/core/colors';
import styled from 'styled-components';

export const FilterIcon = styled.div`
    background: ${indigo[600]};
    color: #fff;
    border-radius: 15px;
    height: 30px;
    line-height: 30px;
    width: 60px;
    text-align: center;
    margin-bottom: 0.5rem;
    position: relative;
    cursor: pointer;
`;

export const MobileFilter = styled.div`
    display: none;
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    @media only screen and (max-width: 767px) {
        display: block;
    }
`;

export const DesktopFilter = styled.div`
    display: block;
    @media only screen and (max-width: 767px) {
        display: none;
    }
`;

export const MobileDrawer = styled(Drawer)`
    display: none;
    padding: 1rem;
    @media only screen and (max-width: 767px) {
        display: block;
    }
    > div {
        padding: 1rem;
    }
`;

export const DesktopDrawer = styled(Drawer)`
    display: block;
    @media only screen and (max-width: 767px) {
        display: none;
    }
    > div {
        padding: 1rem;
    }
`;

export const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -10,
        top: 7,
        padding: '0 4px',
    },
}))(Badge);

export const StyledDesktopTransactionBadge = withStyles((theme) => ({
    badge: {
        right: 0,
        top: 7,
        padding: '0 4px',
    },
}))(Badge);

export const MobileStyledBadge = withStyles((theme) => ({
    badge: {
        right: -8,
        top: 7,
        padding: '0 4px',
    },
}))(Badge);

export const FilterButton = styled.span`
    width: 19.977%;
    display: inline-block;
    text-align: center;
    color: #fff;
    line-height: 30px;
    border-right: 1px solid #888;
    cursor: pointer;
    box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.5);
    background-color: ${grey[700]};
    &:last-child {
        border: 0;
    }
`;
