import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton, Backdrop, CircularProgress, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { searchAddr } from '../../api/searchAddr';
import { setMoveCenter } from '../../modules/filterMarker';
import Tooltip from './Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
    root: props => ({
        padding: props.padding,
        display: 'flex',
        alignItems: 'center',
        width: props.width,
    }),
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        paddingTop: 5,
    },
    iconButton: {
        padding: '10px 5px',
    },
    divider: {
        height: 28,
        margin: 2,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function SearchBox(props) {
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [guide] = useState([
        '- 주소 검색은 실거래 완료된 매물만 검색됨',
        '- 실거래가 제공지역 (서울시, 성남시)',
        '- 화면을 이동하거나 검색으로 찾아보기',
        '- 예) 신사동, 삼성역, 봉은사사거리, 삼성동167',
        '- 카톡문의 → kavills'
    ]);

    const onSearch = useCallback(() => {
        if (value) {
            setLoading(true);
            getAddr(value);
        }
    }, [value]);

    const getAddr = useCallback(
        async (addr) => {
            const response = await searchAddr(addr);

            dispatch(setMoveCenter({
                coord_x: response.data.data.coord_x,
                coord_y: response.data.data.coord_y,
                addr: response.data.data.addr,
            }));

            setLoading(false);
        },
        [],
    );

    const onSerachHandler = (e) => {
        onSearch();
        e.preventDefault(); // 이거 안해주면 새로고침 + 깜빡거림
    }

    return (
        <>
            <Paper component="form" className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="지역 / 주소 / 지하철 검색"
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            onSerachHandler(e);
                        }
                    }}
                />
                <IconButton
                    type="submit"
                    onClick={(e) => onSerachHandler(e)}
                    className={classes.iconButton}
                    aria-label="search"
                >
                    <SearchIcon />
                </IconButton>
                <Divider
                    className={classes.divider}
                    orientation="vertical"
                />
                <Tooltip
                    open={false}
                    guide={guide}
                    icon={<HelpOutlineIcon />}
                    top={-15}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}