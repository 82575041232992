import React, { useMemo } from 'react';
import ButtonGroupFilter from '../../filters/ButtonGroupFilter';
import { Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { themeOptions } from '../../../lib/filterOptions';

function FilterTheme({ defaultSelected, onChangeThemeOptions }) {
    const options = useMemo(() => themeOptions, []);
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        component="h6"
                        style={{ color: grey[200] }}
                    >
                        테마 조건 설정
                    </Typography>
                </Grid>
            </Grid>
            <ButtonGroupFilter
                options={options}
                defaultSelected={defaultSelected}
                multiple
                onChangeSelectedOptions={onChangeThemeOptions}
            />
        </>
    );
}
export default FilterTheme;
