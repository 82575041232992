import React, { useState, useEffect } from 'react';
import { Icon, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import * as Tags from './tags/BuildingItemTags';

function BuildingItem({ item, onClickBuilding, favorites, onClickFavorite }) {
    const [favorite, setFavorite] = useState(false);

    useEffect(() => {
        const isFavorite = favorites.includes(item.id);
        setFavorite(isFavorite);
    }, [favorites, item.id]);

    return (
        <Tags.BuildingItem
            onClick={() => {
                onClickBuilding({ ...item, type: 'sales' });
            }}
        >
            {favorite ? (
                <Icon
                    className="fas fa-star"
                    onClick={(event) => {
                        onClickFavorite(event, item);
                    }}
                    style={Tags.FavoriteIconStyle}
                ></Icon>
            ) : (
                    <Icon
                        className="far fa-star"
                        onClick={(event) => {
                            onClickFavorite(event, item);
                        }}
                        style={Tags.FavoriteIconStyle}
                    ></Icon>
                )}
            <Tags.BuildingImage src={item.image} />
            <Tags.BuildingDetailWrap>
                <div style={{ ...Tags.RowStyle, paddingTop: 0 }}>
                    <Typography variant="subtitle2">{item.area}</Typography>
                </div>
                <Tags.BuildingDetailHeader style={Tags.RowStyle}>
                    <span>매매가 : </span>
                    <NumberFormat
                        value={item.amount / 10000}
                        displayType={'text'}
                        thousandSeparator={true}
                    />
                    억원
                </Tags.BuildingDetailHeader>
                <div style={Tags.RowStyle}>
                    <span>평단가 : </span>
                    <NumberFormat
                        value={item.lnd_py_amount}
                        displayType={'text'}
                        thousandSeparator={true}
                    />
                    만원
                </div>
                <div style={Tags.RowStyle}>
                    <span>대지: </span>
                    {item.lnd_extent}㎡ |<span>연면적:</span>
                    {item.lnd_total_extent}㎡
                </div>
                <div className="specification" style={Tags.RowStyle}>
                    추천 용도: {item.recommend_category.join(', ')}
                </div>
            </Tags.BuildingDetailWrap>
        </Tags.BuildingItem>
    );
}

export default React.memo(BuildingItem);
