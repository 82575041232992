import styled from 'styled-components';

export const Wrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    background: #f9f9f9;
`;

export const Header = styled.h2`
    font-size: 1.2em;
    padding: 15px 10px;
    box-shadow: 0 4px 2px -2px gray;
    background: #666;
    color: #fff;
`;

export const Buildings = styled.ul`
    padding: 0 10px;
    background: #f9f9f9;
`;

export const DetailWrap = styled.div`
    margin-top: 30px;
    height: 100%;

    @media only screen and (max-width: 767px) {
        margin-top: 75px;
    }
`;