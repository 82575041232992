import axios from 'axios';

export const searchAddr = async (addr) => {
    const response = await axios.get(`//jeonginpmc.com/api/getCoord.php`, {
        params: {
            addr,
        },
    });
    return response;
};
