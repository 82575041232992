import moment from 'moment';
import { useOptions } from './filterOptions';

const numberFormat = (value, unit) => {
    return (
        Math.round(value)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + unit
    );
};
export const makeContent = (
    marker,
    kakaoOptions,
    unitPrice,
    unitSize,
    selectedClass,
) => {
    let content = `<div></div>`;
    if (kakaoOptions.level > 0) {
        if (kakaoOptions.level <= 3) {
            let amount =
                unitPrice === 'amount'
                    ? `${numberFormat(marker.amount / 10000, '억')}`
                    : `${numberFormat(marker.lnd_py_amount, '만')}`;

            let type;
            if (marker.type_code === 'CB') {
                type = '상업용';
            } else if (marker.type_code === 'LD') {
                type = '토지';
                if (unitPrice === 'price') {
                    amount = `${numberFormat(marker.lnd_py_amount, '만')}`;
                }
            } else if (marker.type_code === 'HD') {
                type = '주거용';
            }

            if (
                isNaN(Math.round(marker.lnd_extent_py)) ||
                isNaN(Math.round(marker.lnd_extent))
            ) {
            }
            const size =
                unitSize === 'pyeong'
                    ? `${Math.round(marker.lnd_extent_py)}평`
                    : `${Math.round(marker.lnd_extent)}㎡`;

            content = `<div class="customoverlay" onclick="overlayEvent('${marker.id
                }')">
						<div class="circle">${size}</div>
						<div class="body ${selectedClass}" >
							<div >${type}</div>
							<div>${amount}</div>
							</div>
							<div class="footer">
							${marker.transaction ? `${marker.transaction}년` : `0000년`}
							</div>
					</div>`;
        } else if (kakaoOptions.level <= 5) {
            const price = numberFormat(marker.lnd_pyeong_price, '만');
            content = `<div class="customoverlay ${marker.count > 20 ? 'aaa' : 'bbb'
                }"><div class="body"><div>평균가</div><div>${price}</div></div><div class="footer">${marker.count
                }개</div>`;
        } else {
            content = `<div class="customoverlay area"><div class="body"><div>${marker.addr}</div></div><div class="footer">${marker.count}개</div></div>`;
        }
    }
    return content;
};

export const getIsFilter = (marker, filter) => {
    if (!filter.typeCode.includes(marker.type_code)) {
        return true;
    }

    if (filter.price && marker.amount < filter.price) {
        return true;
    }

    if (filter.transaction && marker.transaction < filter.transaction) {
        return true;
    }

    if (filter.size.lnd.min !== 1 && filter.size.lnd.max !== 9999999) {
        if (
            Number(marker.lnd_extent_py) < filter.size.lnd.min ||
            Number(marker.lnd_extent_py) > filter.size.lnd.max
        ) {
            return true;
        }
    }

    if (filter.size.bd.min !== 1 && filter.size.bd.max !== 9999999) {
        if (marker.type_code === 'LD') {
            return true;
        }
        if (
            Number(marker.lnd_total_extent) / 3.3 < filter.size.bd.min ||
            Number(marker.lnd_total_extent) / 3.3 > filter.size.bd.max
        ) {
            return true;
        }
    }
    if (!filter.use.includes(marker.lnd_use) && marker.ownership === false) {
        return true;
    }

    if (filter.use.length !== useOptions.length && marker.ownership === true) {
        return true;
    }

    return false;
};

export const getBasicInfo = (building) => {
    const basicInfo = [
        {
            label: '토지면적',
            body: [building.lnd_extent, Math.round(building.lnd_extent * 0.3025)],
            unit: ['㎡', 'py'],
        },
    ];

    if (building.type_code !== 'LD') {
        basicInfo.push({
            label: '연면적',
            body: [
                building.lnd_total_extent,
                Math.round(building.lnd_total_extent * 0.3025),
            ],
            unit: ['㎡', 'py'],
        });
    }
    const floor = [];
    if (building.floors && building.floors.length === 2 && building.floors[1]) {
        floor.push(`지하 ${building.floors[1]}층`);
    }
    if (building.floors && building.floors[0] && building.floors[0]) {
        floor.push(`지상 ${building.floors[0]}층`);
    }
    if (floor.length > 0) {
        basicInfo.push({
            label: '층수',
            body: floor.join(' / '),
        });
    }

    if (
        building.type_code !== 'LD' &&
        moment(building.complete_date).isValid()
    ) {
        basicInfo.push({
            label: '준공년도',
            body: `${moment(building.complete_date).format('YYYY')}년`,
        });
    }

    if (building.use) {
        basicInfo.push({
            label: '용도',
            body: building.use,
        });
    } else if (building.lnd_use) {
        basicInfo.push({
            label: '용도',
            body: building.lnd_use,
        });
    }

    if (!building.ownership && building.type_code !== 'LD') {
        basicInfo.push({
            label: '건폐율',
            body: building.coverage_rate ? `${building.coverage_rate}%` : '-',
        });

        basicInfo.push({
            label: '용적률',
            body: building.ratio_area ? `${building.ratio_area}%` : '-',
        });

        basicInfo.push({
            label: '주차장',
            body: building.parking_count || '-',
        });

        basicInfo.push({
            label: '승강기',
            body: building.elvator || '-',
        });
    }

    return basicInfo;
};

export const shuffle = (array) => {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

const groups = [
    { level: 'A', items: [1, 2, 3] },
    { level: 'B', items: [4, 5] },
    { level: 'C', items: [6, 7] },
    { level: 'D', items: [8, 9, 10] },
];

export const getGroupByLevel = (level) => {
    const group = groups.find((gp) => gp.items.includes(level));
    return group ? group.level : 'E';
};

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
