import React, { useState, useEffect } from 'react';
import { FormControlLabel, FormControl } from '@material-ui/core';
import IOSSwitch from './IOSSwitch';

function FilterFavorite({ defaultSelected, onChangeFavoriteOptions }) {
    const [state, setState] = useState(defaultSelected);

    const handleChange = (event) => {
        setState(event.target.checked);
    };

    useEffect(() => {
        onChangeFavoriteOptions(state);
    }, [onChangeFavoriteOptions, state]);

    return (
        <FormControl component="fieldset">
            <FormControlLabel
                control={
                    <IOSSwitch
                        checked={state}
                        onChange={handleChange}
                        name="checkedB"
                    />
                }
                style={{ color: '#fff' }}
                label="관심목록보기"
            />
        </FormControl>
    );
}
export default FilterFavorite;
