import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './common/Header';
import MapPage from '../Routes/MapPage';
import styled from 'styled-components';

const Wrap = styled.div`
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    color: #333;
    overflow: hidden;
    // @media only screen and (max-width: 767px) {
    //     top: 120px;
    // }
`;

function router() {
    return (
        <Router>
            <Header />
            <Wrap>
                {/* 한번에 하나의 Route 만 선택되도록 함 */}
                <Route path="/map" exact component={MapPage} />
                <Route path="/map2" exact component={MapPage} />
            </Wrap>
        </Router>
    );
}

export default router;
