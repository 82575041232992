import { typeCodeOptions, useOptions } from '../lib/filterOptions';
import moment from 'moment';

const SET_TYPE_CODE_FILTER_MARKER = 'filter_marker/SET_TYPE_FILTER_MARKER';
const SET_PRICE_FILTER_MARKER = 'filter_marker/SET_PRICE_FILTER_MARKER';
const SET_TRANSACTION_FILTER_MARKER =
    'filter_marker/SET_TRANSACTION_FILTER_MARKER';
const SET_SIZE_FILTER_MARKER = 'filter_marker/SET_SIZE_FILTER_MARKER';
const SET_USE_FILTER_MARKER = 'filter_marker/SET_USE_FILTER_MARKER';
const SET_MOVE_CENTER = 'filter_marker/SET_MOVE_CENTER';

export const setTypeCodeFilterMarker = (payload) => ({
    type: SET_TYPE_CODE_FILTER_MARKER,
    payload,
});

export const setPriceFilterMarker = (payload) => ({
    type: SET_PRICE_FILTER_MARKER,
    payload,
});

export const setTransactionFilterMarker = (payload) => ({
    type: SET_TRANSACTION_FILTER_MARKER,
    payload,
});

export const setSizeFilterMarker = (payload) => ({
    type: SET_SIZE_FILTER_MARKER,
    payload,
});

export const setUseFilterMarker = (payload) => ({
    type: SET_USE_FILTER_MARKER,
    payload,
});

export const setMoveCenter = (payload) => ({
    type: SET_MOVE_CENTER,
    payload,
});

const initialState = JSON.parse(
    window.localStorage.getItem('markers_filter'),
) || {
    typeCode: typeCodeOptions.map((o) => o.value),
    price: undefined,
    transaction: moment().add('0', 'year').format('YYYY'),
    size: {
        lnd: {
            min: 1,
            max: 9999999,
        },
        bd: {
            min: 1,
            max: 9999999,
        },
    },
    use: useOptions.map((o) => o.value),
    addrInfo: {
        coord_x: null,
        coord_y: null,
        addr: null,
    }
};

export default function filterBuilding(state = initialState, action) {
    let newState;
    switch (action.type) {
        case SET_TYPE_CODE_FILTER_MARKER:
            newState = {
                ...state,
                typeCode: action.payload,
            };
            break;
        case SET_PRICE_FILTER_MARKER:
            newState = {
                ...state,
                price: action.payload,
            };
            break;
        case SET_TRANSACTION_FILTER_MARKER:
            newState = {
                ...state,
                transaction: action.payload,
            };
            break;
        case SET_SIZE_FILTER_MARKER:
            newState = {
                ...state,
                size: action.payload,
            };
            break;
        case SET_USE_FILTER_MARKER:
            newState = {
                ...state,
                use: action.payload,
            };
            break;
        case SET_MOVE_CENTER:
            newState = {
                ...state,
                addrInfo: {
                    ...action.payload,
                }
            };
            break;
        default:
            return state;
    }
    window.localStorage.setItem('markers_filter', JSON.stringify(newState));
    return { ...newState };
}
