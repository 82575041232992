import styled from "styled-components";
import { grey } from "@material-ui/core/colors";

export const Wrap = styled.div`
    position: fixed;
    background: #aaa;
    width: 380px;
    height: 30px;
    z-index: 999999;
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const FilterButton = styled.span`
    width: 25%;
    display: inline-block;
    text-align: center;
    color: #fff;
    line-height: 30px;
    border-right: 1px solid #888;
    cursor: pointer;
    box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.5);
    background-color: ${(props) => (props.active ? grey[700] : grey[500])};
    &:last-child {
        border: 0;
    }
`;

export const FilterDetail = styled.div`
    background: #fff;
    background: #ddd;
    box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.5);
    padding: 0.5rem;
`;

export const MobileRightTop = styled.div`
    display: none;
    height: 44px;
    color: #fff;
    background: ${grey[800]};
    font-size: 1.2em;
    line-height: 44px;
    padding: 0 1rem;
    @media only screen and (max-width: 767px) {
        display: block;
    }
`;

export const ActionButton = styled.div`
    display: none;
    position: absolute;
    right: 0;
    top: 34%;
    padding: 0 10px;
    @media only screen and (max-width: 767px) {
        display: block;
    }
`;
