import styled from 'styled-components';
import { Icon } from '@material-ui/core';
import { pink, grey } from '@material-ui/core/colors';

export const Wrap = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 9999999;
    overflow-y: scroll;
    overflow-x: hidden;
    background: ${grey[200]};
`;

export const CloseIcon = styled(Icon)`
    position: absolute;
    right: 1rem;
    top: 0.9rem;
    cursor: pointer;
`;

export const HeaderDivistion = styled.div`
    background: ${grey[800]};
    color: #fff;
    padding: 1rem;
    font-size: 1.4em;
    z-index: 999;
    padding-left: ${(props) => (props.dc ? '40px' : '10px')};
    position: fixed;
    width: 380px;
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const Badge = styled.div`
    background: ${pink[600]};
    position: absolute;
    font-size: 0.7em;
    top: 0;
    left: 0;
    line-height: 1.2em;
    width: 30px;
    height: 100%;
    padding: 0 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BuildingImage = styled.div`
	position: absolute;
	border: 1px solid ${grey[500]};
	width: 100%;
	height: 400px;
	background-image: url("${(props) => props.src}");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
`;
