import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    iconButton: {
        padding: '10px 5px',
        float: 'right',
    },
}));

export default function TooltipComponent(props) {
    const { guide, icon, top } = props;
    const classes = useStyles(props);
    const [titles] = useState(guide);
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            top: top,
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 340,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
            padding: 10,
        },
        arrow: {
            color: '#f5f5f9',
        },
    }))(Tooltip);

    const handleTooltipOpen = () => {
        setOpen(!open);
        localStorage.setItem('bookmark', !open);
    }

    return (
        <HtmlTooltip
            title={
                <>
                    {
                        titles.map((text, index) =>
                            <Typography key={index} variant="body2" color="inherit">
                                {text}
                            </Typography>
                        )
                    }
                </>
            }
            open={open}
            arrow
        >
            <IconButton
                className={classes.iconButton}
                aria-label="help"
                onClick={handleTooltipOpen}
            >
                {icon}
            </IconButton>
        </HtmlTooltip>
    );
}