import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const globalStyles = createGlobalStyle`
    ${reset};
    a {
        text-decoration:none;
        color:inherit;
    }
    * {
        box-sizing:border-box;
    }
    body {
			font-family: 'Nanum Gothic', sans-serif;
			font-size:12px;
			background-color: white;
			color:white;
    }
`;

export default globalStyles;
