import React, { useMemo } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { KakaoMap, withJs, withKakaoMap, Marker } from '../kakao';

import PricePaper from './buildingDetail/PricePaper';
import BasicInfo from './buildingDetail/BasicInfo';
import ChipBundle from './buildingDetail/ChipBundle';
import NameCard from './buildingDetail/NameCard';

import * as Tags from './tags/BuildingDetailTags';
import { getBasicInfo } from '../../lib/util';
import Specific from './buildingDetail/Specific';
import BasicInfoItem from './filter/BasicInfoItem';

function BuildingDetail({ onClickCloseBuildingDetail, building }) {
    const basicInfo = useMemo(() => getBasicInfo(building), [building]);
    const Kakao = useMemo(
        () =>
            withJs(
                `//dapi.kakao.com/v2/maps/sdk.js?appkey=afcffd1af4b07b35403ca06dae8eed7d&libraries=services,clusterer,drawing&autoload=false`,
            )(withKakaoMap(KakaoMap)),
        [],
    );

    const kakaoOptions = useMemo(() => {
        return {
            averageCenter: true,
            zoomable: false,
            draggable: false,
            maxLevel: 0,
            lat: building.coord_y,
            lng: building.coord_x,
            level: 1,
            roadview: true,
        };
    }, [building]);

    return (
        <Tags.Wrap>
            <Tags.HeaderDivistion
                dc={building.type === 'sales' && building.is_premium === 'Y'}
            >
                <Typography variant="caption">매매번호 :</Typography>
                {building.id}
                {building.type === 'sales' && building.is_premium === 'Y' && (
                    <Tags.Badge>급매물</Tags.Badge>
                )}
                <Tags.CloseIcon
                    className="fas fa-times"
                    style={{
                        fontSize: 20,
                        cursor: 'pointer',
                        color: grey[200],
                        zIndex: 9999,
                    }}
                    onClick={onClickCloseBuildingDetail}
                />
            </Tags.HeaderDivistion>
            <div style={{ height: '51px' }}></div>
            <Grid
                container
                style={{ width: '100% !importnat', margin: '0 !important' }}
            >
                {!building.ownership ? (
                    <Grid item xs={12} style={{ height: '300px' }}>
                        <Kakao options={kakaoOptions}>
                            <Marker
                                key={building.id}
                                info={building}
                                options={{
                                    lat: building.coord_y,
                                    lng: building.coord_x,
                                    image:
                                        '//www.jeonginpmc.com/images/circle_pin.png',
                                }}
                            ></Marker>
                        </Kakao>
                    </Grid>
                ) : (
                    <Grid item xs={12} style={{ height: '400px' }}>
                        <Tags.BuildingImage src={building.image} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container style={{ padding: '0.5rem' }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                {building.type === 'case'
                                    ? building.addr
                                    : building.area}
                            </Typography>
                            {!building.ownership && (
                                <Typography variant="caption">
                                    {building.addr_old}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ textAlign: 'right', position: 'relative' }}
                        >
                            <PricePaper
                                label="매매가"
                                cols={12}
                                body={Math.round(building.amount / 10000)}
                                unit="억원"
                            />
                        </Grid>
                        {building.type === 'case' && building.transaction && (
                            <Grid item xs={12}>
                                <BasicInfoItem
                                    info={{
                                        label: '거래시점',
                                        body: `${building.transaction}.${building.transaction_month}`,
                                        unit: '㎡',
                                    }}
                                ></BasicInfoItem>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {building.type_code === 'LD' ? (
                                    <Grid item xs={12}>
                                        <BasicInfo
                                            basicInfo={[
                                                {
                                                    label: '토지평당가',
                                                    body: building.lnd_py_amount
                                                        ? Math.round(
                                                            building.lnd_py_amount,
                                                        )
                                                        : 0,
                                                    unit: '만원',
                                                },
                                            ]}
                                        ></BasicInfo>
                                    </Grid>
                                ) : (
                                    <>
                                        <PricePaper
                                            label="토지평당가"
                                            cols={6}
                                            body={
                                                building.lnd_py_amount
                                                    ? Math.round(
                                                        building.lnd_py_amount,
                                                    )
                                                    : ''
                                            }
                                            unit={building.lnd_py_amount ? "만" : ''}
                                        />
                                        <PricePaper
                                            label="건물평당가"
                                            cols={6}
                                            body={
                                                building.bd_py_amount
                                                    ? Math.round(
                                                        building.bd_py_amount,
                                                    )
                                                    : ''
                                            }
                                            unit={building.bd_py_amount ? "만" : ''}
                                        />
                                    </>
                                )}

                                {(building.type === 'sales' ||
                                    building.ownership) && (
                                        <>
                                            {building.deposit && (
                                                <PricePaper
                                                    label="보증금"
                                                    cols={4}
                                                    body={building.deposit}
                                                    unit="만원"
                                                />
                                            )}
                                            {building.rent_profit && (
                                                <PricePaper
                                                    label="월임대료"
                                                    cols={4}
                                                    body={building.rent_profit}
                                                    unit="만원"
                                                />
                                            )}
                                            {building.manage_amount && (
                                                <PricePaper
                                                    label="관리비"
                                                    cols={4}
                                                    body={building.manage_amount}
                                                    unit="만원"
                                                />
                                            )}
                                            {building.earning_rate && (
                                                <PricePaper
                                                    label="수익률"
                                                    cols={4}
                                                    body={building.earning_rate}
                                                    unit="%"
                                                />
                                            )}
                                            {building.mortgage && (
                                                <PricePaper
                                                    label="융자"
                                                    cols={4}
                                                    body={building.mortgage}
                                                    unit="억원"
                                                />
                                            )}
                                            {building.earning_rate_l && (
                                                <PricePaper
                                                    label="L 수익률"
                                                    cols={4}
                                                    body={building.earning_rate_l}
                                                    unit="%"
                                                />
                                            )}
                                        </>
                                    )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        기본 정보
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <BasicInfo
                                        basicInfo={basicInfo}
                                    ></BasicInfo>
                                </Grid>
                            </Grid>
                        </Grid>
                        {building.ownership ? (
                            <>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">
                                                추천용도
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ChipBundle
                                                chips={
                                                    building.recommend_category
                                                }
                                            ></ChipBundle>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">
                                                특징
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Specific
                                                value={building.specification}
                                            ></Specific>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xx={12}>
                                            <Typography variant="subtitle2">
                                                문의전화
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NameCard
                                                name={building.writer[0]}
                                                contact={building.writer[1]}
                                            ></NameCard>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xx={12}>
                                        <Typography variant="subtitle2">
                                            문의전화
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NameCard
                                            name="㈜정인부동산중개법인"
                                            contact="02-516-1188"
                                        ></NameCard>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <div>
                                <Typography variant="caption">
                                    <p>
                                        <span>㈜정인부동산중개법인 </span>
                                        <span
                                            style={{ margin: '0 0.7rem' }}
                                        >
                                            대표: 최원용
                                        </span>
                                    </p>
                                    <p>부동산등록번호: 11680-2016-00291</p>
                                    <p>
                                        서울특별시 강남구 테헤란로625,
                                        덕명빌딩17층
                                    </p>
                                </Typography>
                            </div>
                            <div style={{ marginTop: '8px' }}>
                                <Typography variant="caption">
                                    <p>
                                        <span>정인 PMC </span>
                                        <span
                                            style={{ margin: '0 0.7rem' }}
                                        >
                                            대표: 전영권
                                        </span>
                                    </p>
                                    <p>사업자등록번호: 211-01-56042</p>
                                    <p>
                                        서울특별시 강남구 테헤란로625,
                                        덕명빌딩17층
                                    </p>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <img
                                src="//www.jeonginpmc.com/img/logo-white.png"
                                style={{ width: '80%' }}
                                alt="명함"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Tags.Wrap>
    );
}

export default React.memo(
    BuildingDetail,
    (prevState, nextState) => prevState.building === nextState.building,
);
