import React, { useState, useMemo } from 'react';
import { DialogContent, DialogActions, Button } from '@material-ui/core';

import ButtonGroupFilter from '../ButtonGroupFilter';
import { pink } from '@material-ui/core/colors';
import { typeCodeOptions } from '../../../lib/filterOptions';

function FilterTypeCode({
    defaultSelected,
    onChangeTypeCodeOptions,
    handleClose,
}) {
    const options = useMemo(() => typeCodeOptions, []);
    const [selected, setSelected] = useState(defaultSelected);

    return (
        <>
            <DialogContent>
                <div
                    style={{
                        width: '500',
                        minWidth: '230px',
                    }}
                >
                    <ButtonGroupFilter
                        options={options}
                        defaultSelected={defaultSelected}
                        multiple={true}
                        xs={4}
                        md={4}
                        onChangeSelectedOptions={(options) => {
                            setSelected(options);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        onChangeTypeCodeOptions(selected);
                    }}
                    color="secondary"
                    style={{ color: '#fff' }}
                >
                    확인
                </Button>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    color="secondary"
                    style={{ color: pink[600] }}
                >
                    취소
                </Button>
            </DialogActions>
        </>
    );
}

export default React.memo(FilterTypeCode);
