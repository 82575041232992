import moment from 'moment';

export const priceOptions = [
    { label: '10억이상', value: 100000 },
    { label: '20억이상', value: 200000 },
    { label: '30억이상', value: 300000 },
    { label: '40억이상', value: 400000 },
    { label: '60억이상', value: 600000 },
    { label: '100억이상', value: 1000000 },
    { label: '200억이상', value: 2000000 },
    { label: '300억이상', value: 3000000 },
];

export const lndSizeOptions = [
    { label: '10평대', value: '10-20' },
    { label: '20평대', value: '20-30' },
    { label: '30평대', value: '30-40' },
    { label: '40평대', value: '40-50' },
    { label: '50평대', value: '50-60' },
    { label: '70평대', value: '70-80' },
    { label: '80평대', value: '80-90' },
    { label: '100평대', value: '100-200' },
    { label: '200평대', value: '200-300' },
    { label: '500평대', value: '500-1000' },
    { label: '1000평대', value: '1000-2000' },
    { label: '5000평대', value: '5000-6000' },
    { label: '전체', value: '1-9999999' },
];

export const bdSizeOptions = [
    { label: '10평대', value: '10-20' },
    { label: '100평대', value: '100-200' },
    { label: '200평대', value: '200-300' },
    { label: '500평대', value: '500-600' },
    { label: '1000평대', value: '1000-2000' },
    { label: '2000평대', value: '2000-3000' },
    { label: '5000평대', value: '5000-6000' },
    { label: '전체', value: '1-9999999' },
];

export const transactionOptions = [
    {
        label: '최근 1년',
        value: moment().add('0', 'year').format('YYYY'),
    },
    {
        label: '최근 2년',
        value: moment().add('-1', 'year').format('YYYY'),
    },
    {
        label: '최근 3년',
        value: moment().add('-2', 'year').format('YYYY'),
    },
    {
        label: '최근 5년',
        value: moment().add('-4', 'year').format('YYYY'),
    },
    {
        label: '최근 8년',
        value: moment().add('-7', 'year').format('YYYY'),
    },
    {
        label: '최근 10년',
        value: moment().add('-9', 'year').format('YYYY'),
    },
    {
        label: '최근 13년',
        value: moment().add('-12', 'year').format('YYYY'),
    },
    {
        label: '최근 16년',
        value: moment().add('-15', 'year').format('YYYY'),
    },
];

export const typeCodeOptions = [
    { label: '상업용', value: 'CB' },
    { label: '주거용', value: 'HD' },
    { label: '토지', value: 'LD' },
];

export const useOptions = [
    { label: '1종전주', value: '1종전주' },
    { label: '준주거', value: '준주거' },
    { label: '전용공업', value: '전용공업' },
    { label: '보전녹지', value: '보전녹지' },
    { label: '2종전주', value: '2종전주' },
    { label: '중심상업', value: '중심상업' },
    { label: '일반공업', value: '일반공업' },
    { label: '생산녹지', value: '생산녹지' },
    { label: '1종일주', value: '1종일주' },
    { label: '일반상업', value: '일반상업' },
    { label: '준공업', value: '준공업' },
    { label: '자연녹지', value: '자연녹지' },
    { label: '2종일주', value: '2종일주' },
    { label: '근린상업', value: '근린상업' },
    { label: '개발제한', value: '개발제한' },
    { label: '농림지역', value: '농림지역' },
    { label: '3종일주', value: '3종일주' },
    { label: '유통상업', value: '유통상업' },
    { label: '미정', value: '미정' },
    { label: '자연환경', value: '자연환경' },
    { label: '관리지역', value: '관리지역' },
    { label: '보전관리', value: '보전관리' },
    { label: '생산관리', value: '생산관리' },
    { label: '계획관리', value: '계획관리' },
];

export const locationOptions = [
    { label: '강남권', value: '강남권' },
    { label: '도심권', value: '도심권' },
    { label: '강북권', value: '강북권' },
    { label: '강동권', value: '강동권' },
    { label: '강서권', value: '강서권' },
    { label: '분당권', value: '분당권' },
];

export const themeOptions = [
    { label: '사옥', value: '사옥' },
    { label: '임대', value: '임대' },
    { label: '수익', value: '수익' },
    { label: '사옥임대', value: '사옥임대' },
    { label: '투자', value: '투자' },
    { label: '신축', value: '신축' },
    { label: '리모', value: '리모' },
    { label: '증여', value: '증여' },
];
