import React, { Fragment, useMemo, useCallback } from 'react';
import styled from 'styled-components';

import FilterContainer from '../../containers/FilterContainer';
import {
    KakaoMap,
    withJs,
    withKakaoMap,
    Marker,
    CustomOverlay,
} from '../kakao';
import { makeContent, getIsFilter } from '../../lib/util';

const MapWrap = styled.div`
    position: relative;
    width: calc(100% - 380px);
    @media only screen and (max-width: 767px) {
        position: absolute;
        top: 30px;
        bottom: 44px;
        left: 0;
        right: 0;
        width: 100%;
    }
`;

function Map({
    markers,
    kakaoOptions,
    unitSize,
    unitPrice,
    onBoundsChange,
    onMoveCenter,
    onChangeUnitPrice,
    onChangeUnitSize,
    onChangeFilter,
    filter,
    searchedAddr,
}) {
    const Kakao = useMemo(
        () =>
            withJs(
                `//dapi.kakao.com/v2/maps/sdk.js?appkey=afcffd1af4b07b35403ca06dae8eed7d&libraries=services,clusterer,drawing&autoload=false`,
            )(withKakaoMap(KakaoMap)),
        [],
    );

    const isFiltered = useCallback(
        (markers) => {
            return getIsFilter(markers, filter);
        },
        [filter],
    );

    const filteredMarkers = markers.map((marker) => ({
        ...marker,
        filtered: (filter && isFiltered(marker)) || false,
        contents: makeContent(
            marker,
            kakaoOptions,
            unitPrice,
            unitSize,
            kakaoOptions.level <= 3 &&
                searchedAddr &&
                (marker.addr || marker.addr_old) &&
                searchedAddr.some((addr) => {
                    const re = new RegExp(`${addr}$`, 'i');
                    return (
                        (marker.addr &&
                            marker.addr.replace(/\s/g, '').search(re) >= 0) ||
                        (marker.addr_old &&
                            marker.addr_old.replace(/\s/g, '').search(re) >= 0)
                    );
                })
                ? 'selected'
                : '',
        ),
    }));

    return (
        <MapWrap>
            <FilterContainer
                onMoveCenter={onMoveCenter}
                onChangeUnitPrice={onChangeUnitPrice}
                onChangeUnitSize={onChangeUnitSize}
                onChangeFilter={onChangeFilter}
            ></FilterContainer>
            <Kakao options={kakaoOptions} onBoundsChange={onBoundsChange}>
                {filteredMarkers &&
                    filteredMarkers.map((marker, index) => (
                        <Fragment key={index}>
                            <Marker
                                key={marker.id}
                                info={marker}
                                options={{
                                    lat: marker.coord_y,
                                    lng: marker.coord_x,
                                    image:
                                        kakaoOptions.level <= 3 &&
                                            marker.filtered
                                            ? '//www.jeonginpmc.com/images/circle_grey.png'
                                            : '//www.jeonginpmc.com/images/circle_pin.png',
                                }}
                                filtered={marker.filtered}
                            ></Marker>
                            {(kakaoOptions.level <= 3 && marker.filtered) || (
                                <CustomOverlay
                                    className="custom_overlay"
                                    info={marker}
                                    options={{
                                        lat: marker.coord_y,
                                        lng: marker.coord_x,
                                        content: marker.contents,
                                    }}
                                />
                            )}
                        </Fragment>
                    ))}
            </Kakao>
        </MapWrap>
    );
}
export default React.memo(Map);
