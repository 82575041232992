import React, { useContext } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import NumberFormat from 'react-number-format';
import ThemesContext from '../../../Context/ThemesContext';

function BasicInfo({ basicInfo }) {
    const themes = useContext(ThemesContext);
    return (
        <>
            {basicInfo &&
                basicInfo.map((info) => (
                    <ThemeProvider key={info.label} theme={themes.light}>
                        <Paper
                            variant="outlined"
                            square
                            style={{
                                padding: '0.3rem',
                                textAlign: 'center',
                            }}
                        >
                            <Grid
                                container
                                style={{ padding: '0 0.3rem' }}
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        textAlign: 'left',
                                        display: 'flex',
                                    }}
                                >
                                    <Typography variant="caption">
                                        {info.label}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    style={{ textAlign: 'right' }}
                                >
                                    {Array.isArray(info.body) ? (
                                        <Typography variant="body1">
                                            <NumberFormat
                                                value={info.body[0]}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                            />
                                            {info.unit[0]}
                                            &nbsp;/&nbsp;
                                            <Typography variant="caption">
                                                <NumberFormat
                                                    value={info.body[1]}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                                {info.unit[1]}
                                            </Typography>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">
                                            {isNaN(info.body) === false ? (
                                                <>
                                                    <NumberFormat
                                                        value={info.body}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </>
                                            ) : (
                                                <span>{info.body}</span>
                                            )}
                                            {info.unit && info.unit}
                                        </Typography>
                                    )}
                                    {info.comment && (
                                        <Typography
                                            variant="caption"
                                            style={{
                                                display: 'inline-block',
                                                marginTop: '-5px',
                                            }}
                                        >
                                            {info.comment}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </ThemeProvider>
                ))}
        </>
    );
}

export default React.memo(BasicInfo);
