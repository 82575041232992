import React, { useState, useCallback } from 'react';

import * as Tags from '../tags/BuildingFilterTags';
import FilterPrice from './FilterPrice';
import FilterTheme from './FilterTheme';
import FilterFavorite from './FilterFavorite';
import FilterLocation from './FilterLocation';
import CloseIcon from '@material-ui/icons/Close';
import { Icon, Badge, withStyles, Button } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import {
    setPriceFilter,
    setLocationFilter,
    setThemeFilter,
    setFavoriteFilter,
} from '../../../modules/filterBuilding';
import { locationOptions, themeOptions } from '../../../lib/filterOptions';

function BuildingFilter({
    filter,
    mobileInfomationnStatus,
    onClickOpenInfomation,
}) {
    const dispatch = useDispatch();
    const [state, setState] = useState(null);

    const onChangePriceOptions = useCallback(
        (options) => {
            dispatch(setPriceFilter(options));
        },
        [dispatch],
    );

    const onChangeLocationOptions = useCallback(
        (options) => {
            dispatch(setLocationFilter(options));
        },
        [dispatch],
    );

    const onChangeThemeOptions = useCallback(
        (options) => {
            dispatch(setThemeFilter(options));
        },
        [dispatch],
    );

    const onChangeFavoriteOptions = useCallback(
        (options) => {
            dispatch(setFavoriteFilter(options));
        },
        [dispatch],
    );

    const OptionBadge = withStyles(() => ({
        badge: {
            right: -10,
            top: 7,
            padding: '0 4px',
        },
    }))(Badge);

    return (
        <Tags.Wrap>
            <Tags.ActionButton>
                {!mobileInfomationnStatus ? (
                    <Icon
                        className="fa fa-arrow-up"
                        onClick={() => {
                            onClickOpenInfomation(true);
                        }}
                        style={{
                            fontSize: 20,
                            color: grey[100],
                        }}
                    ></Icon>
                ) : (
                        <Icon
                            className="fa fa-arrow-down"
                            onClick={() => {
                                onClickOpenInfomation(false);
                            }}
                            style={{
                                fontSize: 20,
                                color: grey[100],
                            }}
                        ></Icon>
                    )}
            </Tags.ActionButton>
            <Tags.MobileRightTop>
                {/* <Button
                    variant="contained"
                    size="small"
                    onClick={() => window.open('https://www.jeonginpmc.com/bbs/board.php?bo_table=news', '_blank')}
                >
                    언론보도
                </Button> */}
                {/* <span style={{ float: 'right', marginRight: '8%' }}> */}
                    실거래 매물 보기
                {/* </span> */}
            </Tags.MobileRightTop>
            <div>
                <Tags.FilterButton
                    active={state === 'price' ? true : false}
                    onClick={() => {
                        setState('price');
                    }}
                >
                    {!filter.price ? (
                        '금액별'
                    ) : (
                            <OptionBadge
                                color="error"
                                variant="dot"
                                invisible={filter.price ? false : true}
                            >
                                금액별
                            </OptionBadge>
                        )}
                </Tags.FilterButton>
                <Tags.FilterButton
                    active={state === 'location' ? true : false}
                    onClick={() => {
                        setState('location');
                    }}
                >
                    {filter.location.length === locationOptions.length ? (
                        '권역별'
                    ) : (
                            <OptionBadge color="error" variant="dot">
                                권역별
                            </OptionBadge>
                        )}
                </Tags.FilterButton>
                <Tags.FilterButton
                    active={state === 'theme' ? true : false}
                    onClick={() => {
                        setState('theme');
                    }}
                >
                    {filter.theme.length === themeOptions.length ? (
                        '테마별'
                    ) : (
                            <OptionBadge color="error" variant="dot">
                                테마별
                            </OptionBadge>
                        )}
                </Tags.FilterButton>
                <Tags.FilterButton
                    active={state === 'favorite' ? true : false}
                    onClick={() => {
                        setState('favorite');
                    }}
                >
                    {!filter.favorite ? (
                        '관심목록'
                    ) : (
                            <OptionBadge color="error" variant="dot">
                                관심목록
                            </OptionBadge>
                        )}
                </Tags.FilterButton>
            </div>
            {state !== null && (
                <Tags.FilterDetail
                    style={{ textAlign: 'right', background: grey[700] }}
                >
                    <div
                        style={{
                            width: '90%',
                            textAlign: 'right',
                            marginBottom: '1rem',
                            position: 'absolute',
                        }}
                    >
                        <CloseIcon
                            color="action"
                            onClick={() => {
                                setState(null);
                            }}
                        />
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        {state === 'price' && (
                            <FilterPrice
                                defaultSelected={filter.price}
                                onChangePriceOptions={onChangePriceOptions}
                            />
                        )}
                        {state === 'location' && (
                            <FilterLocation
                                defaultSelected={filter.location}
                                onChangeLocationOptions={
                                    onChangeLocationOptions
                                }
                            />
                        )}
                        {state === 'theme' && (
                            <FilterTheme
                                defaultSelected={filter.theme}
                                onChangeThemeOptions={onChangeThemeOptions}
                            />
                        )}
                        {state === 'favorite' && (
                            <FilterFavorite
                                defaultSelected={filter.favorite}
                                onChangeFavoriteOptions={
                                    onChangeFavoriteOptions
                                }
                            />
                        )}
                    </div>
                </Tags.FilterDetail>
            )}
        </Tags.Wrap>
    );
}

export default React.memo(BuildingFilter);
