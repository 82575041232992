import styled from 'styled-components';
import { pink, grey } from '@material-ui/core/colors';

export const RowStyle = {
    padding: '4px 0',
};

export const FavoriteIconStyle2 = {
    position: 'absolute',
    fontSize: '17px',
    zIndex: 99,
    width: '20px',
    height: '27px',
    top: '-6px'
};

export const FavoriteIconStyle = {
    color: pink[500],
    position: 'absolute',
    right: 0,
    fontSize: 17,
    zIndex: 99,
};

export const BuildingItem = styled.li`
    position: relative;
    height: 155px;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px dashed ${grey[400]};
`;

export const BuildingImage = styled.div`
	position: absolute;
	border: 1px solid ${grey[500]};
	width: 100px;
	height: 132px;
	background-image: url("${(props) => props.src}");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const BuildingDetailWrap = styled.div`
    position: absolute;
    left: 115px;
    width: 233px;
    height: 150px;
    overflow: hidden;
    .specification {
        line-height: 15px;
    }
`;

export const BuildingDetailHeader = styled.h3`
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 0.2rem;
`;
