export const createPromiseThunk = (type, promiseCreator) => {
    const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];

    return (param) => async (dispatch) => {
        dispatch({ type });
        try {
            const payload = await promiseCreator(param);
            dispatch({
                type: SUCCESS,
                payload,
            });
        } catch (e) {
            dispatch({
                type: ERROR,
                payload: e,
                error: true,
            });
        }
    };
};

export const handleAsyncAction = (type) => {
    const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
    return (state, action) => {
        const reducer =
            type === 'marker/GET_MARKERS' ? reducerMarkerUtils : reducerUtils;

        switch (action.type) {
            case type:
                return {
                    ...state,
                    ...reducer.loading(state.data, action.payload),
                };
            case SUCCESS:
                return {
                    ...state,
                    ...reducer.success(action.payload, state.data),
                };
            case ERROR:
                return {
                    ...state,
                    ...reducer.error(action.payload),
                };
            default:
        }
    };
};

export const reducerUtils = {
    initial: (data = null) => ({
        data,
        loading: false,
        error: null,
    }),
    loading: (prevData) => ({
        data: prevData,
        loading: true,
        error: null,
    }),
    success: (data) => ({
        data,
        loading: false,
        error: null,
    }),
    error: (error) => ({
        data: null,
        loading: false,
        error,
    }),
};

export const reducerMarkerUtils = {
    initial: (data = null) => ({
        data,
        loading: false,
        error: null,
    }),
    loading: (prevData = null, data = null) => {
        return {
            data:
                data.level === prevData.level && data.level !== 'B'
                    ? { ...prevData }
                    : {
                          markers: [],
                          level: data.level,
                      },
            loading: true,
            error: null,
        };
    },
    success: (data, prevData) => {
        const newMarkerIds = data.markers.map((m) => m.id);

        const preMarkers =
            prevData.markers.length < 1000
                ? prevData.markers
                : prevData.markers.filter((pm) => newMarkerIds.includes(pm.id));

        const prevMarkersIds = preMarkers.map((pm) => pm.id);

        return {
            data: {
                markers: [
                    ...preMarkers,
                    ...data.markers.filter(
                        (d) => !prevMarkersIds.includes(d.id),
                    ),
                ],
                level: data.level,
            },
            loading: false,
            error: null,
        };
    },
    error: (error) => ({
        data: null,
        loading: false,
        error,
    }),
};
