import React, { useCallback, useState } from 'react';
import {
    TextField,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Backdrop,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import { searchAddr } from '../../../api/searchAddr';
import { pink } from '@material-ui/core/colors';

function SearchAddr({ handleClose, onMoveCenter }) {
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState();

    const onCLickSearch = useCallback(() => {
        setLoading(true);
        getAddr(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const getAddr = useCallback(
        async (addr) => {
            const response = await searchAddr(addr);
            setLoading(false);
            if (!response.data.result) {
                setAlert(true);
            } else {
                setAlert(false);
                onMoveCenter({
                    coord_x: response.data.data.coord_x,
                    coord_y: response.data.data.coord_y,
                    addr: response.data.data.addr,
                });
                handleClose();
            }
        },
        [handleClose, onMoveCenter],
    );

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();
    return (
        <>
            <DialogContent>
                <div
                    style={{
                        width: '500',
                        minWidth: '230px',
                    }}
                >
                    <TextField
                        id="filled-full-width"
                        label="지역 / 주소 / 지하철 검색"
                        dense="none"
                        fullWidth
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        margin="normal"
                        autoFocus
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                onCLickSearch();
                            }
                        }}
                    />
                    {alert && (
                        <Typography
                            variant="caption"
                            style={{ color: pink[600] }}
                        >
                            존재 하지 않는 지역입니다.
                        </Typography>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCLickSearch}
                    color="secondary"
                    style={{ color: '#fff' }}
                >
                    확인
                </Button>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    color="secondary"
                    style={{ color: pink[600] }}
                >
                    취소
                </Button>
            </DialogActions>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default React.memo(SearchAddr);
