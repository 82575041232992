import { useContext, useState, useEffect, useCallback } from 'react';
import { MapContext } from './KakaoMap';
import { MarkerClustererContext } from './MarkerClusterer';
import { Observable } from 'rxjs';

const Marker = (props) => {
    const { kakao, map } = useContext(MapContext);
    const { clusterer } = useContext(MarkerClustererContext);
    const [state, setState] = useState({
        marker: null,
        kakao,
        map,
        clusterer,
    });

    const setMarkerImage = useCallback(
        (marker, image) => {
            const markerImage = new kakao.maps.MarkerImage(
                image,
                new kakao.maps.Size(8, 8),
            );
            marker.setImage(markerImage);
        },
        [kakao.maps.MarkerImage, kakao.maps.Size],
    );

    const delayObservable = (delay) => {
        return new Observable((observer) => {
            setTimeout(() => {
                observer.next(delay);
                observer.complete();
            }, delay);
        });
    };

    useEffect(() => {
        const { onMouseOver, onMouseOut, options, delay } = props;
        const { lat, lng, image } = options;

        const markerImage = new kakao.maps.MarkerImage(
            image,
            new kakao.maps.Size(8, 8),
        );
        const marker = new kakao.maps.Marker({
            position: new kakao.maps.LatLng(lat, lng),
            image: markerImage,
            clickable: true,
        });

        const subscription = delayObservable(delay ? delay : 0).subscribe({
            next(x) {},
            error(err) {},
            complete() {
                clusterer ? clusterer.addMarker(marker) : marker.setMap(map);
            },
        });

        const mouseOver = () => {
            if (onMouseOver) onMouseOver(marker);
        };
        const mouseOut = () => {
            if (onMouseOut) onMouseOut(marker);
        };
        kakao.maps.event.addListener(marker, 'mouseover', mouseOver);
        kakao.maps.event.addListener(marker, 'mouseout', mouseOut);
        kakao.maps.event.addListener(marker, 'click', function () {
            console.log('Marker - click event');
        });

        setState({ ...state, marker });

        return () => {
            subscription.unsubscribe();
            marker.setMap(null);
            kakao.maps.event.removeListener(marker, 'mouseover', mouseOver);
            kakao.maps.event.removeListener(marker, 'mouseout', mouseOut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { lat, lng } = props.options;
        const { marker } = state;
        if (marker === null) return;
        const position = new kakao.maps.LatLng(lat, lng);
        marker.setPosition(position);
    }, [kakao.maps.LatLng, props.options, state]);

    useEffect(() => {
        const { image } = props.options;
        const { marker } = state;
        if (marker === null || image === null) return;
        if (image) setMarkerImage(marker, image);
    }, [props.options, props.options.image, setMarkerImage, state]);

    return null;
};

export default Marker;
