import { put, takeLatest, call } from 'redux-saga/effects';
import { reducerUtils, handleAsyncAction } from '../lib/asyncUtils';
import * as buildingsAPI from '../api/buildings';

const GET_BUILDINGS = 'building/GET_BUILDINGS';
const GET_BUILDINGS_SUCCESS = 'building/GET_BUILDINGS_SUCCESS';
const GET_BUILDINGS_ERROR = 'building/GET_BUILDINGS_ERROR';

export const getBuildings = (payload) => ({
    type: GET_BUILDINGS,
    payload,
});

function* getBuildingsSaga(action) {
    try {
        const sales = yield call(buildingsAPI.getBuildings, action.payload);
        const buildings = {
            sales: sales.sales || [],
            hots: sales.hots || [],
        };

        yield put({
            type: GET_BUILDINGS_SUCCESS,
            payload: { ...buildings },
        });
    } catch (e) {
        yield put({
            type: GET_BUILDINGS_ERROR,
            payload: e,
            error: true,
        });
    }
}

export function* buildingsSaga() {
    yield takeLatest(GET_BUILDINGS, getBuildingsSaga);
}

const initialState = {
    ...reducerUtils.initial({
        sales: [],
        hots: [],
    }),
};

const getPosterReducer = handleAsyncAction(GET_BUILDINGS);
export default function buildings(state = initialState, action) {
    switch (action.type) {
        case GET_BUILDINGS:
        case GET_BUILDINGS_SUCCESS:
        case GET_BUILDINGS_ERROR:
            return getPosterReducer(state, action);
        default:
            return state;
    }
}
