import styled from "styled-components";

export const InfomationWrap = styled.div`
    position: relative;
    width: 380px;
    background: #f9f9f9;
    @media only screen and (max-width: 767px) {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0px;
        background: #fff;
        z-index: 999999999;
        ${({ isOpenInMobile }) =>
            isOpenInMobile
                ? `
				top: 0;
		`
                : `
				height: 44px;
		`}
    }
`;
