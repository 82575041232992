import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Contents from '../components/contents/Contents';
import { getBuildings } from '../modules/buildingSaga';
import { getMarkers } from '../modules/markerSaga';
import { getGroupByLevel } from '../lib/util';

function MapContainer({ initCenter }) {
    const [center, setCenter] = useState(initCenter);
    const [bound, setBound] = useState({
        southWest: null,
        northEast: null,
        level: null,
    });

    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const { sales, hots, loading: buildingLoading } = useSelector((state) => {
        return {
            sales: state.buildings.data.sales,
            hots: state.buildings.data.hots,
            loading: state.buildings.loading,
        };
    }, shallowEqual);

    const { markers, loading: markersLoading, markersLevel } = useSelector(
        (state) => {
            const { data: markerData, loading } = state.markers;
            return {
                markers: markerData.markers || [],
                markersLevel: markerData.level || '',
                loading,
            };
        },
    );

    const dispatch = useDispatch();
    useEffect(() => {
        if (bound.southWest && bound.northEast && bound.level) {
            dispatch(
                getBuildings({
                    ...bound,
                }),
            );
            if (markersLevel !== getGroupByLevel(bound.level)) {
                dispatch(
                    getMarkers({
                        ...bound,
                        southWest: '0,0',
                        northEast: '0,0',
                    }),
                );

                dispatch(getMarkers(bound));
                setCenter((center) => ({
                    ...center,
                    level: bound.level,
                }));
            } else {
                dispatch(getMarkers(bound));
                setCenter((center) => ({
                    ...center,
                    level: bound.level,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bound, dispatch]);

    const onBoundsChange = useCallback((mapInfo) => {
        setBound({
            southWest: `${mapInfo.swLatLng.Ma},${mapInfo.swLatLng.La}`,
            northEast: `${mapInfo.neLatLng.Ma},${mapInfo.neLatLng.La}`,
            level: mapInfo.level,
        });
    }, []);

    const onClickBuilding = useCallback((building) => {
        setSelectedBuilding(building);
    }, []);

    const onClickCloseBuildingDetail = useCallback(() => {
        setSelectedBuilding(false);
    }, []);

    return (
        <>
            <Contents
                center={center}
                markers={markers}
                sales={sales}
                hots={hots}
                onBoundsChange={onBoundsChange}
                loading={buildingLoading && markersLoading}
                onClickBuilding={onClickBuilding}
                selectedBuilding={selectedBuilding}
                onClickCloseBuildingDetail={onClickCloseBuildingDetail}
            />
        </>
    );
}

export default React.memo(MapContainer);
