import React from 'react';
import styled from 'styled-components';

import Loading from '../common/Loading';
import ScreenContainer from '../../containers/ScreenContainer';
import InfomationContainer from '../../containers/InfomationContainer';

const ContentsWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: strech;
    position: absolute;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
`;

function Contents({
    center,
    markers,
    sales,
    hots,
    loading,
    onBoundsChange,
    onClickBuilding,
    selectedBuilding,
    onClickCloseBuildingDetail,
}) {
    return (
        <ContentsWrap>
            {loading && <Loading />}
            <ScreenContainer
                defaultCenter={center}
                markers={markers}
                onBoundsChange={onBoundsChange}
                onClickBuilding={onClickBuilding}
            />
            <InfomationContainer
                sales={sales}
                hots={hots}
                selectedBuilding={selectedBuilding}
                onClickCloseBuildingDetail={onClickCloseBuildingDetail}
                onClickBuilding={onClickBuilding}
            />
        </ContentsWrap>
    );
}

export default React.memo(Contents);
