import React, { useContext } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ThemesContext from '../../../Context/ThemesContext';

function BasicInfoItem({ info }) {
    const themes = useContext(ThemesContext);
    return (
        <>
            <ThemeProvider key={info.label} theme={themes.light}>
                <Paper
                    variant="outlined"
                    square
                    style={{
                        padding: '0.3rem',
                        textAlign: 'center',
                    }}
                >
                    <Grid
                        container
                        style={{ padding: '0 0.3rem' }}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={4}
                            style={{
                                textAlign: 'left',
                                display: 'flex',
                            }}
                        >
                            <Typography variant="caption">
                                {info.label}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} style={{ textAlign: 'right' }}>
                            <Typography variant="body1">{info.body}</Typography>
                            {info.comment && (
                                <Typography
                                    variant="caption"
                                    style={{
                                        display: 'inline-block',
                                        marginTop: '-5px',
                                    }}
                                >
                                    {info.comment}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
        </>
    );
}

export default BasicInfoItem;
