import React, { useState, useCallback } from 'react';
import * as Tags from './tags/BuildingsTags';
import BuildingItem from './BuildingItem';
import BuildingFilter from './filter/BuildingFilter';
import * as Tags2 from './tags/BuildingItemTags';
import Tooltip from '../common/Tooltip';
import { isMobile } from "react-device-detect";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

function Buildings({
    sales,
    hots,
    filter,
    mobileInfomationnStatus,
    onClickOpenInfomation,
    onClickBuilding,
}) {
    const [favorites, setFavorites] = useState(
        window.localStorage.getItem('favorites')
            ? JSON.parse(window.localStorage.getItem('favorites'))
            : [],
    );

    const [guide] = useState([
        '별표를 클릭해서 관심목록에 저장해 주세요.',
        '특별한 매물을 원하세요?',
        '궁금한 점은 관리자에게 문의하세요'
    ]);

    const onClickFavorite = useCallback(
        (event, item) => {
            event.stopPropagation();

            const isFav = favorites.find((f) => f === item.id);
            const newFav = isFav
                ? [...favorites.filter((f) => f !== item.id)]
                : [...favorites, item.id];
            const newFavorites = JSON.stringify([...newFav]);
            window.localStorage.setItem('favorites', newFavorites);
            setFavorites([...newFav]);
        },
        [favorites],
    );

    const TooltipIcon = () => {
        return (
            <HelpOutlineIcon style={Tags2.FavoriteIconStyle2} />
        )
    }

    const bookmarkGuideVisible = () => {
        if (localStorage.getItem('bookmark') === 'true' || localStorage.getItem('bookmark') === null) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Tags.Wrap>
                <BuildingFilter
                    filter={filter}
                    mobileInfomationnStatus={mobileInfomationnStatus}
                    onClickOpenInfomation={onClickOpenInfomation}
                ></BuildingFilter>
                <Tags.DetailWrap>
                    <Tags.Header>
                        프리미엄 매물 목록
                        {
                            (hots.length || sales.length)
                                ?
                                isMobile
                                    ?
                                    <Tooltip
                                        open={
                                            mobileInfomationnStatus
                                                ?
                                                bookmarkGuideVisible
                                                :
                                                false
                                        }
                                        guide={guide}
                                        icon={<TooltipIcon />}
                                        top={-16}
                                    />
                                    :
                                    <Tooltip
                                        open={bookmarkGuideVisible}
                                        guide={guide}
                                        icon={<TooltipIcon />}
                                        top={-10}
                                    />
                                :
                                null
                        }
                    </Tags.Header>
                    {hots.length > 0 ? (
                        <Tags.Buildings
                            style={{
                                height: '25%',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}
                        >
                            {hots.map((hot) => (
                                <BuildingItem
                                    key={`s_${hot.id}`}
                                    item={hot}
                                    favorites={favorites}
                                    onClickBuilding={onClickBuilding}
                                    onClickFavorite={onClickFavorite}
                                ></BuildingItem>
                            ))}
                        </Tags.Buildings>
                    ) : (
                        <div
                            style={{ lineHeight: '50px', textAlign: 'center' }}
                        >
                            이 지역에 프리미엄 매물이 없습니다
                        </div>
                    )}

                    <Tags.Header>실거래 매물 목록</Tags.Header>
                    {sales.length > 0 ? (
                        <Tags.Buildings>
                            {sales.map((sale) => (
                                <BuildingItem
                                    key={`s_${sale.id}`}
                                    item={sale}
                                    favorites={favorites}
                                    onClickBuilding={onClickBuilding}
                                    onClickFavorite={onClickFavorite}
                                ></BuildingItem>
                            ))}
                        </Tags.Buildings>
                    ) : (
                        <div
                            style={{ lineHeight: '50px', textAlign: 'center' }}
                        >
                            이 지역에 매물이 없습니다
                        </div>
                    )}
                </Tags.DetailWrap>
            </Tags.Wrap>
        </>
    );
}

export default Buildings;
