import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer, { rootSaga } from './modules';
import crreateSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

const sagaMiddleware = crreateSagaMiddleware();
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware, logger)),
);
const rootElement = document.getElementById('root');

sagaMiddleware.run(rootSaga);
const RootApp = () => (
    <Provider store={store}>
        <App />
    </Provider>
);
if (rootElement.hasChildNodes()) {
    hydrate(<RootApp />, rootElement);
} else {
    render(<RootApp />, rootElement);
}
