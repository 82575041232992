import React, { useState, useMemo } from 'react';
import {
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@material-ui/core';
import { pink } from '@material-ui/core/colors';

import ButtonGroupFilter from '../ButtonGroupFilter';
import { lndSizeOptions, bdSizeOptions } from '../../../lib/filterOptions';
import SizeRange from './SizeRange';
import CustomSwitch from '../CustomSwitch';
import { useCallback } from 'react';

function FilterSize({ defaultSelected, handleClose, onChangeSizeOptions }) {
    const lndOptions = useMemo(() => lndSizeOptions, []);
    const bdOptions = useMemo(() => bdSizeOptions, []);
    const [bdSize, setBdSize] = useState(defaultSelected.bd);
    const [lndSize, setLndSize] = useState(defaultSelected.lnd);

    const defaultUnit =
        window.localStorage.getItem('unit_size_filter') || 'pyeong';
    const [unit, setUnit] = useState(defaultUnit);

    const onChangeUnitSize = useCallback((unit) => {
        window.localStorage.setItem('unit_size_filter', unit);
        setUnit(unit);
    }, []);

    const onChangeLndSize = useCallback(({ min, max }) => {
        setLndSize({ min, max });
    }, []);

    const onChangeBdSize = useCallback(({ min, max }) => {
        setBdSize({ min, max });
    }, []);
    return (
        <>
            <DialogContent>
                <div
                    style={{
                        position: 'absolute',
                        top: '15px',
                        right: '30px',
                        width: '60px',
                    }}
                >
                    <CustomSwitch
                        options={[
                            { label: '평', value: 'pyeong' },
                            {
                                label: '㎡',
                                value: 'm2',
                            },
                        ]}
                        selected={defaultUnit}
                        onChangeSelected={onChangeUnitSize}
                    ></CustomSwitch>
                </div>
                <div
                    style={{
                        width: '500',
                        minWidth: '230px',
                    }}
                >
                    <Typography variant="subtitle2">토지 면적 (㎡)</Typography>
                    <SizeRange
                        unit={unit}
                        value={lndSize}
                        onChangeSize={onChangeLndSize}
                    ></SizeRange>
                    <ButtonGroupFilter
                        xs={4}
                        md={3}
                        options={lndOptions}
                        hideAllButton="true"
                        defaultSelected={undefined}
                        onChangeSelectedOptions={(options) => {
                            if (options) {
                                const [min, max] = options.split('-');
                                setLndSize({
                                    min: Number(min),
                                    max: Number(max),
                                });
                            }
                        }}
                    />
                    <Typography
                        style={{ marginTop: '20px' }}
                        variant="subtitle2"
                    >
                        연면적 (㎡)
                    </Typography>
                    <SizeRange
                        unit={unit}
                        value={bdSize}
                        onChangeSize={onChangeBdSize}
                    ></SizeRange>
                    <ButtonGroupFilter
                        xs={6}
                        md={3}
                        options={bdOptions}
                        hideAllButton="true"
                        onChangeSelectedOptions={(options) => {
                            if (options) {
                                const [min, max] = options.split('-');
                                setBdSize({
                                    min: Number(min),
                                    max: Number(max),
                                });
                            }
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        onChangeSizeOptions({
                            lnd: lndSize,
                            bd: bdSize,
                        });
                    }}
                    color="secondary"
                    style={{ color: '#fff' }}
                >
                    확인
                </Button>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    color="secondary"
                    style={{ color: pink[600] }}
                >
                    취소
                </Button>
            </DialogActions>
        </>
    );
}

export default FilterSize;
