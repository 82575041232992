/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import Wrapper from './Wrapper';

export const MapContext = React.createContext({});

const KakaoMap = (props) => {
    const [state, setState] = useState({
        map: null,
        kakao: props.kakao,
    });
    const {
        lat,
        lng,
        level,
        zoom,
        mapType,
        bounds,
        zoomable,
        draggable,
        roadview,
        initSearchAddr,
        searchedAddr,
    } = props.options;

    const addZoomControl = (map, kakao, zoom) => {
        if (zoom) {
            map.addControl(
                new kakao.maps.ZoomControl(),
                kakao.maps.ControlPosition[zoom],
            );
        }
    };

    const addMapTypeControl = (map, kakao, mapType) => {
        if (mapType) {
            map.addControl(
                new kakao.maps.MapTypeControl(),
                kakao.maps.ControlPosition[mapType],
            );
        }
    };

    const setLatLngBounds = (map, kakao, bounds) => {
        if (bounds) {
            const latLngBounds = new kakao.maps.LatLngBounds();
            bounds.forEach((b) => {
                latLngBounds.extend(new kakao.maps.LatLng(b.lat, b.lng));
            });
            map.setBounds(latLngBounds);
        }
    };

    const setZoomable = (map, kako, zoomable) => {
        if (zoomable === false) {
            map.setZoomable(false);
        }
    };

    const setDraggable = (map, kako, draggable) => {
        if (draggable === false) {
            map.setDraggable(draggable);
        }
    };

    const setCentertLocalStorage = useCallback((center) => {
        if (!window.localStorage.getItem('last_center')) {
            // 초깃값 [강남구청역]으로 셋팅.
            window.localStorage.setItem(
                'last_center',
                JSON.stringify([37.518079877647025, 127.03972382675438]),
            );
        } else {
            window.localStorage.setItem(
                'last_center',
                JSON.stringify([center.Ma, center.La]),
            );
        }
    });

    const handleLoaded = useCallback((node) => {
        const { kakao, onZoomChang, onBoundsChange } = props;
        if (state.map || node === null) {
            return;
        }

        const map = new kakao.maps.Map(node, {
            level: level || 9,
            center: new kakao.maps.LatLng(lat, lng),
        });

        addZoomControl(map, kakao, zoom);
        addMapTypeControl(map, kakao, mapType);
        setLatLngBounds(map, kakao, bounds);
        setZoomable(map, kakao, zoomable);
        setDraggable(map, kakao, draggable);

        const zoomChange = () => {
            boundsChange();
        };

        const boundsChange = () => {
            if (onBoundsChange) {
                const bounds = map.getBounds();
                const swLatLng = bounds.getSouthWest();
                const neLatLng = bounds.getNorthEast();

                onBoundsChange({
                    swLatLng,
                    neLatLng,
                    center: map.getCenter(),
                    level: map.getLevel(),
                    searchedAddr: searchedAddr, // 일단 사용안함().
                    initSearchAddr: () => initSearchAddr(), // 일단 사용안함.
                });
            }
            setCentertLocalStorage(map.getCenter());
        };
        kakao.maps.event.addListener(map, 'zoom_changed', zoomChange);
        kakao.maps.event.addListener(map, 'dragend', boundsChange);

        setState({ map, kakao });
        boundsChange();
        return () => {
            kakao.maps.event.removeListener(map, 'zoom_changed', zoomChange);
            kakao.maps.event.removeListener(map, 'dragend', boundsChange);
        };
    }, []);

    const rv_container = useRef();
    const map_wrapper = useRef();
    const rv_wrapper = useRef();
    useEffect(() => {
        if (roadview) {
            const rc = new kakao.maps.RoadviewClient();
            const rv = new kakao.maps.Roadview(rv_container.current); // 로드뷰 객체 생성
            const mapCenter = new kakao.maps.LatLng(lat, lng);
            const rvResetValue = {}; //로드뷰의 초기화 값을 저장할 변수
            rc.getNearestPanoId(mapCenter, 50, function (panoId) {
                rv.setPanoId(panoId, mapCenter); //좌표에 근접한 panoId를 통해 로드뷰를 실행합니다.
                rvResetValue.panoId = panoId;
                if (panoId) {
                    map_wrapper.current.style.display = 'none';
                    rv_wrapper.current.style.display = 'block';
                } else {
                    map_wrapper.current.style.display = 'block';
                    rv_wrapper.current.style.display = 'none';
                }
            });
        }
    }, [lat, lng]);

    useEffect(() => {
        if (
            typeof lat === 'string' &&
            typeof lng === 'string' &&
            state.map &&
            state.kakao
        ) {
            state.map.setCenter(
                new state.kakao.maps.LatLng(Number(lat), Number(lng)),
            );

            // 주소 검색시 처리
            if (props.onBoundsChange) {
                state.map.setLevel(3); // 검색시 3레벨 만큼 확대해서 보여줌

                const bounds = state.map.getBounds();
                const swLatLng = bounds.getSouthWest();
                const neLatLng = bounds.getNorthEast();

                props.onBoundsChange({
                    swLatLng,
                    neLatLng,
                    center: state.map.getCenter(),
                    level: 3, //state.map.getLevel(),
                });
                setCentertLocalStorage(state.map.getCenter());
            }
        }
    }, [lat, lng]);

    return (
        <Wrapper id="map-canvas">
            {roadview ? (
                <>
                    <div
                        id="mapWrapper"
                        style={{
                            width: '100%',
                            height: '300px',
                            float: 'left',
                        }}
                        ref={map_wrapper}
                    >
                        <div
                            id="kakao-map"
                            style={{ width: '100%', height: '100%' }}
                            ref={handleLoaded}
                        >
                            {state.map === null ? null : (
                                <MapContext.Provider value={state}>
                                    {props.children}
                                </MapContext.Provider>
                            )}
                        </div>
                    </div>
                    <div
                        id="rvWrapper"
                        style={{
                            width: '100%',
                            height: '300px',
                            float: 'left',
                        }}
                        ref={rv_wrapper}
                    >
                        <div
                            id="roadview"
                            style={{ width: '100%', height: '100%' }}
                            ref={rv_container}
                        ></div>
                    </div>
                </>
            ) : (
                    <Wrapper id="kakao-map" ref={handleLoaded}>
                        {state.map === null ? null : (
                            <MapContext.Provider value={state}>
                                {props.children}
                            </MapContext.Provider>
                        )}
                    </Wrapper>
                )}
        </Wrapper>
    );
};

export default React.memo(KakaoMap);
