import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import buildings, { buildingsSaga } from './buildingSaga';
import markers, { markerSaga } from './markerSaga';
import filterBuilding from './filterBuilding';
import filterMarker from './filterMarker';

export default combineReducers({
    markers,
    buildings,
    filterBuilding,
    filterMarker,
});

export function* rootSaga() {
    yield all([buildingsSaga(), markerSaga()]);
}
