import { useState, useContext, useEffect } from 'react';
import { MapContext } from './KakaoMap';

const CustomOverlay = (props) => {
    const { kakao, map } = useContext(MapContext);
    const [state, setState] = useState({
        overlay: null,
        kakao,
        map,
    });

    useEffect(() => {
        const { lat, lng } = props.options;
        // if (state.overlay !== null) return;
        const content = document.createElement('div');
        content.className = props.className;
        content.innerHTML = props.options.content;
        const overlay = new kakao.maps.CustomOverlay({
            position: new kakao.maps.LatLng(lat, lng),
            content: content,
            xAnchor: -0.05,
            yAnchor: 1.3,
        });

        overlay.setZIndex(99);
        overlay.setMap(map);
        setState({ ...state, overlay });
        return () => {
            overlay.setMap(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options.content]);

    useEffect(() => {
        const { lat, lng } = props.options;
        const { overlay } = state;
        if (overlay === null) return;
        overlay.setPosition(new kakao.maps.LatLng(lat, lng));
    }, [kakao.maps.LatLng, props.options, state]);
    return null;
};

export default CustomOverlay;
