import React, { useState, useMemo } from 'react';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';

import ButtonGroupFilter from '../ButtonGroupFilter';
import { priceOptions } from '../../../lib/filterOptions';

function FilterPrice({ defaultSelected, handleClose, onChangePriceOptions }) {
    const options = useMemo(() => priceOptions, []);

    const [selected, setSelected] = useState(defaultSelected);

    return (
        <>
            <DialogContent>
                <div
                    style={{
                        width: '500',
                        minWidth: '230px',
                    }}
                >
                    <ButtonGroupFilter
                        xs={6}
                        md={6}
                        options={options}
                        defaultSelected={defaultSelected}
                        onChangeSelectedOptions={(options) => {
                            setSelected(options);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        onChangePriceOptions(selected);
                    }}
                    color="secondary"
                    style={{ color: '#fff' }}
                >
                    확인
                </Button>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    color="secondary"
                    style={{ color: pink[600] }}
                >
                    취소
                </Button>
            </DialogActions>
        </>
    );
}

export default React.memo(FilterPrice);
