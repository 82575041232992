import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Grid, Paper } from '@material-ui/core';
import styled from 'styled-components';
import { grey } from '@material-ui/core/colors';

const ButtonPaper = styled(Paper)`
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    font-size: 9px;
    background: ${(prop) =>
        prop.active === 'active' ? grey[900] : grey[300]} !important;
    color: ${(prop) =>
        prop.active === 'active' ? grey[100] : grey[500]} !important;
`;
function ButtonGroupFilter({
    defaultSelected,
    options,
    onChangeSelectedOptions,
    multiple,
    xs,
    md,
    hideAllButton,
}) {
    const [selectedOptions, setSelectedOptions] = useState(defaultSelected);
    const onclickOption = useCallback(
        (option) => {
            if (multiple) {
                if (selectedOptions.includes(option)) {
                    setSelectedOptions((selectedOptions) => {
                        return [
                            ...selectedOptions.filter((so) => so !== option),
                        ];
                    });
                } else {
                    setSelectedOptions((selectedOptions) => {
                        return [...selectedOptions, option];
                    });
                }
            } else {
                setSelectedOptions(option);
            }
        },
        [multiple, selectedOptions],
    );

    const onclickDefault = useCallback(() => {
        if (multiple) {
            setSelectedOptions([...options.map((o) => o.value)]);
        } else {
            setSelectedOptions(undefined);
        }
    }, [multiple, options]);

    useEffect(() => {
        onChangeSelectedOptions(selectedOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    const isActive = useCallback(
        (option) => {
            if (multiple) {
                if (selectedOptions.includes(option.value)) {
                    return 'active';
                } else {
                    return 'inactive';
                }
            } else {
                if (selectedOptions === option.value) {
                    return 'active';
                } else {
                    return 'inactive';
                }
            }
        },
        [multiple, selectedOptions],
    );

    const isDefault = useMemo(() => {
        if (multiple) {
            // eslint-disable-next-line no-self-compare
            if (selectedOptions.length === options.length) {
                return 'active';
            } else {
                return 'inactive';
            }
        } else {
            if (!selectedOptions) {
                return 'active';
            } else {
                return 'inactive';
            }
        }
    }, [multiple, options.length, selectedOptions]);
    return (
        <>
            <Grid container style={{ marginTop: '1rem' }}>
                {hideAllButton !== 'true' && (
                    <Grid item xs={12}>
                        <ButtonPaper
                            variant="outlined"
                            square
                            active={isDefault}
                            onClick={() => {
                                onclickDefault();
                            }}
                        >
                            전체 선택
                        </ButtonPaper>
                    </Grid>
                )}
                {options &&
                    options.map((option, index) => (
                        <Grid item xs={xs || 3} md={md || 3} key={index}>
                            <ButtonPaper
                                variant="outlined"
                                square
                                active={isActive(option)}
                                onClick={() => {
                                    onclickOption(option.value);
                                }}
                            >
                                {option.label}
                            </ButtonPaper>
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default React.memo(ButtonGroupFilter);
