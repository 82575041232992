import React, { useState, useCallback, useEffect } from 'react';
import * as Tags from './tags/FilterDetailTags';
import CustomSwitch from './CustomSwitch';
import { Dialog, DialogTitle } from '@material-ui/core';
import SearchAddr from './components/SearchAddr';
import FilterTypeCode from './components/FilterTypeCode';
import FilterPrice from './components/FilterPrice';
import FilterTransaction from './components/FilterTransaction';
import FilterSize from './components/FilterSize';
import FilterUse from './components/FilterUse';
import { typeCodeOptions, useOptions } from '../../lib/filterOptions';

function FilterDetail({
    filter,
    onMoveCenter,
    onChangeUnitPrice,
    onChangeUnitSize,
    onChangeTypeCodeOptions,
    onChangePriceOptions,
    onChangeTransactionOptions,
    onChangeSizeOptions,
    onChangeUseOptions,
}) {
    const [openFilter, setOpenFilter] = useState(false);

    const defaultUnitPrice =
        window.localStorage.getItem('unit_price') || 'amount';
    const defaultUnitSize =
        window.localStorage.getItem('unit_size') || 'pyeong';
    const [defaultUnitSizeState, setDefaultUnitSizeState] = useState(
        defaultUnitPrice,
    );
    const [defaultUnitPriceState, setdefaultUnitPriceState] = useState(
        defaultUnitSize,
    );

    useEffect(() => {
        setdefaultUnitPriceState(defaultUnitPrice);
    }, [defaultUnitPrice]);

    useEffect(() => {
        setDefaultUnitSizeState(defaultUnitSize);
    }, [defaultUnitSize]);

    const handleClickOpen = useCallback((filterType) => {
        setOpenFilter(filterType);
    }, []);

    const handleClose = () => {
        setOpenFilter(false);
    };

    return (
        <>
            <Tags.MobileFilter>
                <Tags.FilterButton
                    onClick={() => {
                        handleClickOpen('type');
                    }}
                >
                    {filter.typeCode.length === typeCodeOptions.length ? (
                        '유형'
                    ) : (
                        <Tags.MobileStyledBadge color="error" variant="dot">
                            유형
                        </Tags.MobileStyledBadge>
                    )}
                </Tags.FilterButton>
                <Tags.FilterButton
                    onClick={() => {
                        handleClickOpen('price');
                    }}
                >
                    {!filter.price ? (
                        '가격'
                    ) : (
                        <Tags.MobileStyledBadge color="error" variant="dot">
                            가격
                        </Tags.MobileStyledBadge>
                    )}
                </Tags.FilterButton>
                <Tags.FilterButton
                    onClick={() => {
                        handleClickOpen('transaction');
                    }}
                >
                    {!filter.transaction ? (
                        '거래년도'
                    ) : (
                        <Tags.MobileStyledBadge color="error" variant="dot">
                            거래년도
                        </Tags.MobileStyledBadge>
                    )}
                </Tags.FilterButton>
                <Tags.FilterButton
                    onClick={() => {
                        handleClickOpen('size');
                    }}
                >
                    {filter.size.lnd.min === 1 &&
                        filter.size.lnd.max === 9999999 &&
                        filter.size.bd.min === 1 &&
                        filter.size.bd.max === 9999999 ? (
                        '면적'
                    ) : (
                        <Tags.MobileStyledBadge color="error" variant="dot">
                            면적
                        </Tags.MobileStyledBadge>
                    )}
                </Tags.FilterButton>
                <Tags.FilterButton
                    onClick={() => {
                        handleClickOpen('use');
                    }}
                >
                    {filter.use.length === useOptions.length ? (
                        '용도'
                    ) : (
                        <Tags.MobileStyledBadge color="error" variant="dot">
                            용도
                        </Tags.MobileStyledBadge>
                    )}
                </Tags.FilterButton>
            </Tags.MobileFilter>
            <div
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                }}
            >
                <Tags.FilterIcon>
                    <CustomSwitch
                        options={[
                            { label: '총액', value: 'amount' },
                            { label: '단가', value: 'price' },
                        ]}
                        selected={defaultUnitPriceState}
                        onChangeSelected={onChangeUnitPrice}
                    ></CustomSwitch>
                </Tags.FilterIcon>
                <Tags.FilterIcon>
                    <CustomSwitch
                        options={[
                            { label: '평', value: 'pyeong' },
                            {
                                label: '㎡',
                                value: 'm2',
                            },
                        ]}
                        selected={defaultUnitSizeState}
                        onChangeSelected={onChangeUnitSize}
                    ></CustomSwitch>
                </Tags.FilterIcon>
                <Tags.DesktopFilter>
                    <Tags.FilterIcon
                        onClick={() => {
                            handleClickOpen('type');
                        }}
                    >
                        {filter.typeCode.length === typeCodeOptions.length ? (
                            '유형'
                        ) : (
                            <Tags.StyledBadge color="error" variant="dot">
                                유형
                            </Tags.StyledBadge>
                        )}
                    </Tags.FilterIcon>

                    <Tags.FilterIcon
                        onClick={() => {
                            handleClickOpen('price');
                        }}
                    >
                        {!filter.price ? (
                            '가격'
                        ) : (
                            <Tags.StyledBadge color="error" variant="dot">
                                가격
                            </Tags.StyledBadge>
                        )}
                    </Tags.FilterIcon>
                    <Tags.FilterIcon
                        onClick={() => {
                            handleClickOpen('transaction');
                        }}
                    >
                        {!filter.transaction ? (
                            '거래년도'
                        ) : (
                            <Tags.StyledDesktopTransactionBadge
                                color="error"
                                variant="dot"
                            >
                                거래년도
                            </Tags.StyledDesktopTransactionBadge>
                        )}
                    </Tags.FilterIcon>
                    <Tags.FilterIcon
                        onClick={() => {
                            handleClickOpen('size');
                        }}
                    >
                        {filter.size.lnd.min === 1 &&
                            filter.size.lnd.max === 9999999 &&
                            filter.size.bd.min === 1 &&
                            filter.size.bd.max === 9999999 ? (
                            '면적'
                        ) : (
                            <Tags.StyledBadge color="error" variant="dot">
                                면적
                            </Tags.StyledBadge>
                        )}
                    </Tags.FilterIcon>
                    <Tags.FilterIcon
                        onClick={() => {
                            handleClickOpen('use');
                        }}
                    >
                        {filter.use.length === useOptions.length ? (
                            '용도'
                        ) : (
                            <Tags.StyledBadge color="error" variant="dot">
                                용도
                            </Tags.StyledBadge>
                        )}
                    </Tags.FilterIcon>
                    <Tags.FilterIcon
                        onClick={() => {
                            window.open('https://www.jeonginpmc.com/bbs/board.php?bo_table=news', '_blank');
                        }}
                    >
                        언론보도
                    </Tags.FilterIcon>
                </Tags.DesktopFilter>
                <Dialog
                    open={openFilter ? true : false}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {openFilter === 'type' && '유형 선택'}
                        {openFilter === 'price' && '가격 설정'}
                        {openFilter === 'transaction' && '거래년도 선택'}
                        {openFilter === 'size' && '면적 / 연면적 설정'}
                        {openFilter === 'use' && '용도 선택'}
                        {openFilter === 'addr' && '지역 검색'}
                    </DialogTitle>
                    {openFilter === 'type' && (
                        <FilterTypeCode
                            handleClose={handleClose}
                            defaultSelected={filter.typeCode}
                            onChangeTypeCodeOptions={onChangeTypeCodeOptions}
                        />
                    )}
                    {openFilter === 'price' && (
                        <>
                            <div style={{ marginLeft: 25, fontSize: '0.8rem' }}>
                                금액대를 설정하시고 빠르게 실거래 매물을 검색하세요.
                            </div>
                            <FilterPrice
                                handleClose={handleClose}
                                defaultSelected={filter.price}
                                onChangePriceOptions={onChangePriceOptions}
                            />
                        </>
                    )}
                    {openFilter === 'transaction' && (
                        <>
                            <div style={{ marginLeft: 25, fontSize: '0.8rem' }}>
                                <span style={{ display: 'block', marginBottom: 6 }}>
                                    - 거래년도 선택으로 원하는 실거래확인
                                </span>
                                <span>- 최근1년으로 선택시 현재 거래시세 확인가능</span>
                            </div>
                            <FilterTransaction
                                handleClose={handleClose}
                                defaultSelected={filter.transaction}
                                onChangeTransactionOptions={
                                    onChangeTransactionOptions
                                }
                            />
                        </>
                    )}
                    {openFilter === 'size' && (
                        <FilterSize
                            handleClose={handleClose}
                            defaultSelected={filter.size}
                            onChangeSizeOptions={onChangeSizeOptions}
                        />
                    )}
                    {openFilter === 'use' && (
                        <FilterUse
                            handleClose={handleClose}
                            defaultSelected={filter.use}
                            onChangeUseOptions={onChangeUseOptions}
                        />
                    )}
                    {openFilter === 'addr' && (
                        <SearchAddr
                            handleClose={handleClose}
                            onMoveCenter={onMoveCenter}
                        ></SearchAddr>
                    )}
                </Dialog>
            </div>
        </>
    );
}

export default React.memo(FilterDetail);
