import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import { grey, pink } from '@material-ui/core/colors';

const themes = {
    light: createMuiTheme({
        palette: {
            type: 'light',
            primary: {
                main: grey[400],
                light: grey[200],
                dark: grey[600],
            },
            secondary: {
                main: grey[600],
                light: grey[400],
                dark: grey[800],
            },
            info: {
                main: pink[500],
            },
        },
    }),
    dark: createMuiTheme({
        palette: {
            type: 'dark',
            primary: {
                main: grey[800],
                light: grey[200],
                dark: grey[600],
            },
            secondary: {
                main: grey[900],
                light: grey[400],
                dark: grey[800],
            },
            info: {
                main: pink[500],
            },
        },
    }),
};

export default React.createContext(themes);
