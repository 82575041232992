import React, { useState, useCallback, useMemo } from 'react';
import Infomation from '../components/infomation/Infomation';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { locationOptions, themeOptions } from '../lib/filterOptions';
import { shuffle } from '../lib/util';

function InfomationContainer({
    sales,
    hots,
    selectedBuilding,
    onClickCloseBuildingDetail,
    onClickBuilding,
}) {
    const [mobileInfomationnStatus, setMobileInfomationStatus] = useState(
        false,
    );

    const onClickOpenInfomation = useCallback((status) => {
        setMobileInfomationStatus(status);
    }, []);
    const filter = useSelector((state) => state.filterBuilding, shallowEqual);

    const [filteredSales, filteredHots] = useMemo(() => {
        let filteredSales = _.cloneDeep(sales);
        let filteredHots = _.cloneDeep(hots);

        if (filter.price) {
            filteredSales = filteredSales.sort((a, b) => a.amount - b.amount);
            filteredHots = filteredHots.sort((a, b) => a.amount - b.amount);
            filteredSales = filteredSales.filter(
                (sale) => sale.amount >= filter.price,
            );
            filteredHots = filteredHots.filter(
                (hot) => hot.amount >= filter.price,
            );
        }

        if (filter.location.length !== locationOptions.length) {
            let address_district = [];
            if (filter.location.includes('강남권')) {
                address_district = address_district.concat([
                    '강남구',
                    '서초구',
                    '송파구',
                ]);
            }
            if (filter.location.includes('도심권')) {
                address_district = address_district.concat([
                    '마포구',
                    '서대문구',
                    '중구',
                    '종로구',
                    '용산구',
                ]);
            }

            if (filter.location.includes('강북권')) {
                address_district = address_district.concat([
                    '강북구',
                    '노원구',
                    '도봉구',
                    '성북구',
                    '은평구',
                ]);
            }
            if (filter.location.includes('강동권')) {
                address_district = address_district.concat([
                    '강동구',
                    '광진구',
                    '성동구',
                    '중랑구',
                    '동대문구',
                ]);
            }
            if (filter.location.includes('강서권')) {
                address_district = address_district.concat([
                    '강서구',
                    '관악구',
                    '구로구',
                    '금천구',
                    '동작구',
                    '양천구',
                    '영등포구',
                ]);
            }
            if (filter.location.includes('분당권')) {
                address_district = address_district.concat([
                    '성남시 수정구',
                    '성남시 중원구',
                    '성남시 분당구',
                ]);
            }
            filteredSales = filteredSales.filter((sale) =>
                address_district.includes(sale.address_district),
            );

            filteredHots = filteredHots.filter((hot) =>
                address_district.includes(hot.address_district),
            );
        }

        if (filter.theme.length !== themeOptions.length) {
            filteredSales = filteredSales.filter((sale) =>
                filter.theme.some((theme) =>
                    sale.recommend_category.includes(theme),
                ),
            );

            filteredHots = filteredHots.filter((hot) =>
                filter.theme.some((theme) =>
                    hot.recommend_category.includes(theme),
                ),
            );
        }

        if (filter.favorite) {
            const favoritesString =
                window.localStorage.getItem('favorites') || undefined;
            const favorites = favoritesString
                ? JSON.parse(favoritesString)
                : [];

            filteredSales = filteredSales.filter((sale) =>
                favorites.includes(sale.id),
            );
            filteredHots = filteredHots.filter((hot) =>
                favorites.includes(hot.id),
            );
        }

        // filteredHots = filter.price
        //     ? filteredHots.splice(0, 2)
        //     : shuffle(filteredHots).splice(0, 2);
        filteredHots = filter.price
            ? filteredHots
            : shuffle(filteredHots);

        return [filteredSales, filteredHots];
    }, [sales, hots, filter]);

    return (
        <Infomation
            selectedBuilding={selectedBuilding}
            mobileInfomationnStatus={mobileInfomationnStatus}
            onClickOpenInfomation={onClickOpenInfomation}
            onClickCloseBuildingDetail={onClickCloseBuildingDetail}
            onClickBuilding={onClickBuilding}
            sales={filteredSales}
            hots={filteredHots}
            filter={filter}
        />
    );
}

export default React.memo(
    InfomationContainer,
    (prevProps, nextProps) => prevProps.bound === nextProps,
);
