import React, { useMemo } from 'react';
import ButtonGroupFilter from '../../filters/ButtonGroupFilter';
import { Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { locationOptions } from '../../../lib/filterOptions';

function FilterLocation({ defaultSelected, onChangeLocationOptions }) {
    const options = useMemo(() => locationOptions, []);
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        component="h6"
                        style={{ color: grey[200] }}
                    >
                        권역 조건 설정
                    </Typography>
                </Grid>
            </Grid>
            <ButtonGroupFilter
                options={options}
                multiple={true}
                defaultSelected={defaultSelected}
                onChangeSelectedOptions={onChangeLocationOptions}
            ></ButtonGroupFilter>
        </>
    );
}
export default FilterLocation;
