import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Map from '../components/map/Map';

function ScreenContainer({
    defaultCenter,
    onBoundsChange,
    markers,
    onClickBuilding,
}) {
    const unitPriceStorage =
        window.localStorage.getItem('unit_price') || 'amount';
    const unitSizeStorage =
        window.localStorage.getItem('unit_size') || 'pyeong';

    const [center, setCenter] = useState({ ...defaultCenter });
    const [searchedAddr, setsearchedAddr] = useState('');
    const [kakaoOptions, setKakaoOptions] = useState();
    const [unitPrice, setUnitPrice] = useState(unitPriceStorage);
    const [unitSize, setUnitSize] = useState(unitSizeStorage);
    const [filter, setFilter] = useState();
    // const [refineMarkers, setRefineMarkers] = useState([]);

    const filterState = useSelector(
        (state) => state.filterMarker,
        shallowEqual,
    );

    // 주소 검색시에만 작동됨
    useEffect(() => {
        if (filterState.addrInfo.addr) {
            onMoveCenter(filterState.addrInfo);
        }
    }, [filterState.addrInfo]);

    const onMoveCenter = useCallback((filterState) => {
        const { addr, coord_x, coord_y } = filterState;

        setsearchedAddr(addr);
        setCenter((center) => ({
            ...center,
            latitude: coord_y,
            longitude: coord_x,
        }));
    }, []);

    useEffect(() => {
        setFilter(filterState);
    }, [filterState]);

    // 주소 검색값 초기화
    const initSearchAddr = () => {
        setsearchedAddr('');
    }

    useEffect(() => {
        setKakaoOptions({
            gridSize: 60,
            averageCenter: true,
            maxLevel: 5,
            level: defaultCenter.level,
            lat: center.latitude,
            lng: center.longitude,
            init: center.init,
            initSearchAddr: () => initSearchAddr(),
            searchedAddr: searchedAddr,
        });
    }, [center, defaultCenter]);

    window.overlayEvent = (markerId) => {
        const marker = markers.find((m) => m.id === markerId);
        if (marker) {
            onClickBuilding({ ...marker, type: 'case' });
        }
    };

    const onChangeUnitPrice = useCallback((unit) => {
        window.localStorage.setItem('unit_price', unit);
        setUnitPrice(unit);
    }, []);

    const onChangeUnitSize = useCallback((unit) => {
        window.localStorage.setItem('unit_size', unit);
        setUnitSize(unit);
    }, []);

    return (
        <Map
            onMoveCenter={onMoveCenter}
            markers={markers}
            kakaoOptions={kakaoOptions}
            unitSize={unitSize}
            unitPrice={unitPrice}
            onBoundsChange={onBoundsChange}
            onChangeUnitPrice={onChangeUnitPrice}
            onChangeUnitSize={onChangeUnitSize}
            onChangeFilter={(filter) => {
                setFilter(filter);
            }}
            filter={filter}
            searchedAddr={searchedAddr}
        ></Map>
    );
}

export default React.memo(
    ScreenContainer,
    (prevProps, nextProps) => prevProps.bound === nextProps,
);
