import axios from 'axios';

export const getBuildings = async (param) => {
    const { southWest, northEast, level } = param;
    const response = await axios.get(`//www.jeonginpmc.com/api/sales.php`, {
        params: {
            southWest,
            northEast,
            level,
        },
    });
    return { sales: response.data.sales, hots: response.data.hots };
};
