import React, { useContext } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import NumberFormat from 'react-number-format';
import ThemesContext from '../../../Context/ThemesContext';

function PricePaper({ label, cols, body, unit }) {
    const themes = useContext(ThemesContext);
    return (
        <Grid item xs={cols}>
            <ThemeProvider theme={themes.light}>
                <Paper
                    variant="outlined"
                    square
                    style={{ padding: '0.3rem', textAlign: 'center' }}
                >
                    {cols === 12 ? (
                        <>
                            <Grid container>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        display: 'flex',
                                        paddingLeft: '1rem',
                                        textAlign: 'left',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h6">
                                        {label}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    style={{
                                        paddingRight: '1rem',
                                        textAlign: 'right',
                                    }}
                                >
                                    <Typography variant="h6">
                                        {isNaN(body) === false ? (
                                            <>
                                                <NumberFormat
                                                    value={body}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                            </>
                                        ) : (
                                            <span>{body}</span>
                                        )}
                                        {unit}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Typography
                                variant="caption"
                                style={{ borderBottom: '1px solid #333' }}
                                gutterBottom
                            >
                                {label}
                            </Typography>
                            <Typography variant="body1">
                                {isNaN(body) === false ? (
                                    <>
                                        <NumberFormat
                                            value={body}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                        />
                                    </>
                                ) : (
                                    <span>{body}</span>
                                )}
                                {unit}
                            </Typography>
                        </>
                    )}
                </Paper>
            </ThemeProvider>
        </Grid>
    );
}

export default React.memo(PricePaper);
