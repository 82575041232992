import React, { useContext } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import ThemesContext from './Context/ThemesContext';

import Router from './components/Router';
import GlobalStyles from './components/GlobalStyles';

export default () => {
    const themes = useContext(ThemesContext);
    return (
        <>
            <ThemeProvider theme={themes.dark}>
                <Router />
                <GlobalStyles />
            </ThemeProvider>
        </>
    );
};
