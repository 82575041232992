import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { indigo, grey } from '@material-ui/core/colors';

function CustomSwitch({ options, selected, onChangeSelected }) {
    const [active, setActive] = useState(selected);
    const switchAcive = () => {
        const index = !!options.findIndex((o) => o.value === active);
        const value = options[+!index].value;
        setActive(value);
        onChangeSelected(value);
    };

    useEffect(() => {
        setActive(selected);
    }, [selected]);

    const Option = styled.button`
        position: absolute;
        height: 30px;
        line-height: 30px;
        width: 40px;
        border-radius: 15px;
        background: ${(props) => (props.active ? indigo[600] : grey[400])};
        z-index: ${(props) => (props.active ? 9999 : 9998)};
        color: #fff;
        :focus {
            outline: none;
        }
    `;

    return (
        <div
            onClick={() => {
                switchAcive();
            }}
        >
            <Option style={{ left: 0 }} active={options[0].value === active}>
                {options[0].label}
            </Option>
            <Option style={{ right: 0 }} active={options[1].value === active}>
                {options[1].label}
            </Option>
            )}
        </div>
    );
}

export default CustomSwitch;
