import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import FilterDetail from '../components/filters/FilterDetail';
import { useSelector, useDispatch } from 'react-redux';
import {
    setTypeCodeFilterMarker,
    setPriceFilterMarker,
    setSizeFilterMarker,
    setTransactionFilterMarker,
    setUseFilterMarker,
} from '../modules/filterMarker';

const FilterWrap = styled.div`
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
`;

function FilterContainer({
    onMoveCenter,
    onChangeUnitPrice,
    onChangeUnitSize,
    onChangeFilter,
}) {
    const filterState = useSelector((state) => state.filterMarker);
    const [filter, setFilter] = useState({ ...filterState });
    const dispatch = useDispatch();
    const onChangeTypeCodeOptions = useCallback(
        (selected) => {
            dispatch(setTypeCodeFilterMarker(selected));
            setFilter((filter) => ({
                ...filter,
                typeCode: selected,
            }));
        },
        [dispatch],
    );

    const onChangePriceOptions = useCallback(
        (selected) => {
            dispatch(setPriceFilterMarker(selected));
            setFilter((filter) => ({
                ...filter,
                price: selected,
            }));
        },
        [dispatch],
    );

    const onChangeSizeOptions = useCallback(
        (selected) => {
            dispatch(setSizeFilterMarker(selected));
            setFilter((filter) => ({
                ...filter,
                size: selected,
            }));
        },
        [dispatch],
    );

    const onChangeTransactionOptions = useCallback(
        (selected) => {
            dispatch(setTransactionFilterMarker(selected));
            setFilter((filter) => ({
                ...filter,
                transaction: selected,
            }));
        },
        [dispatch],
    );

    const onChangeUseOptions = useCallback(
        (selected) => {
            dispatch(setUseFilterMarker(selected));
            setFilter((filter) => ({
                ...filter,
                use: selected,
            }));
        },
        [dispatch],
    );

    useEffect(() => {
        onChangeFilter(filter);
    }, [filter, onChangeFilter]);

    return (
        <FilterWrap>
            <FilterDetail
                filter={filter}
                onMoveCenter={onMoveCenter}
                onChangeUnitPrice={onChangeUnitPrice}
                onChangeUnitSize={onChangeUnitSize}
                onChangeTypeCodeOptions={onChangeTypeCodeOptions}
                onChangePriceOptions={onChangePriceOptions}
                onChangeSizeOptions={onChangeSizeOptions}
                onChangeTransactionOptions={onChangeTransactionOptions}
                onChangeUseOptions={onChangeUseOptions}
            />
        </FilterWrap>
    );
}

export default FilterContainer;
