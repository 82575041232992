import React, { useContext } from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ThemesContext from '../../../Context/ThemesContext';

function Specific({ value }) {
    const themes = useContext(ThemesContext);
    return (
        <ThemeProvider theme={themes.light}>
            <Paper
                variant="outlined"
                square
                style={{
                    padding: '0.5rem',
                    textAlign: 'left',
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            style={{ whiteSpace: 'pre-wrap', lineHeight: '1.6' }}
                        >
                            {value}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </ThemeProvider>
    );
}

export default React.memo(Specific);
