import React, { useContext } from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ThemesContext from '../../../Context/ThemesContext';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import { grey } from '@material-ui/core/colors';

function NameCard({ name, contact }) {
    const themes = useContext(ThemesContext);
    return (
        <ThemeProvider theme={themes.light}>
            <Paper
                variant="outlined"
                square
                style={{ padding: '0.5rem', textAlign: 'center' }}
            >
                <Grid container>
                    <Grid item xs={3}>
                        <AccountBoxRoundedIcon
                            style={{
                                color: grey[800],
                                fontSize: '5em',
                                textAlign: 'left',
                            }}
                        ></AccountBoxRoundedIcon>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography
                            variant="h6"
                            style={{ borderBottom: '1px solid #333' }}
                        >
                            {name}
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{ marginTop: '0.5rem' }}
                        >
                            {contact}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </ThemeProvider>
    );
}

export default NameCard;
