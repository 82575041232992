import { put, takeLatest, call } from 'redux-saga/effects';
import { reducerUtils, handleAsyncAction } from '../lib/asyncUtils';
import * as markerAPI from '../api/markers';
import { getGroupByLevel } from '../lib/util';

const GET_MARKERS = 'marker/GET_MARKERS';
const GET_MARKERS_SUCCESS = 'marker/GET_MARKERS_SUCCESS';
const GET_MARKERS_ERROR = 'marker/GET_MARKERS_ERROR';

export const getMarkers = (payload) => ({
    type: GET_MARKERS,
    payload,
});

function* getMarkersSaga(action) {
    try {
        const markers = yield call(markerAPI.getMarkers, action.payload);
        yield put({
            type: GET_MARKERS_SUCCESS,
            payload: {
                markers: markers ? [...markers] : [],
                level: action.payload.level,
            },
        });
    } catch (e) {
        yield put({
            type: GET_MARKERS_ERROR,
            payload: e,
            error: true,
        });
    }
}

export function* markerSaga() {
    yield takeLatest(GET_MARKERS, getMarkersSaga);
}

const initialState = {
    ...reducerUtils.initial({ markers: [], level: null }),
};

const getPosterReducer = handleAsyncAction(GET_MARKERS);
export default function markers(state = initialState, action) {
    switch (action.type) {
        case GET_MARKERS:
        case GET_MARKERS_SUCCESS:
        case GET_MARKERS_ERROR:
            return getPosterReducer(state, {
                ...action,
                payload: {
                    ...action.payload,
                    level: getGroupByLevel(action.payload.level),
                },
            });
        default:
            return state;
    }
}
