import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingWrap = styled.div`
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-align: center;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
`;
const SpinnerRound = styled.div`
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 4px solid black;
    background: transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

function Loading() {
    return (
        <LoadingWrap>
            <div>
                <SpinnerRound></SpinnerRound>
                <div style={{ marginTop: '1rem', color: '#fff' }}>
                    Loading...
                </div>
            </div>
        </LoadingWrap>
    );
}
export default Loading;
