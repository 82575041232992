import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        background: grey[600],
    },
    chip: {
        margin: theme.spacing(0.3),
    },
}));

function ChipBundle({ chips }) {
    const classes = useStyles();
    return (
        <Paper component="ul" className={classes.root}>
            {chips.map((data) => {
                return (
                    <li key={data}>
                        <Chip
                            label={data}
                            variant="outlined"
                            className={classes.chip}
                        />
                    </li>
                );
            })}
        </Paper>
    );
}

export default React.memo(ChipBundle);
