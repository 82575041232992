import axios from 'axios';

export const getMarkers = async (param) => {
    const { southWest, northEast, level } = param;
    const response = await axios.get(`//www.jeonginpmc.com/api/case.php`, {
        params: {
            southWest,
            northEast,
            level,
        },
    });

    if (response.data.cases) {
        return response.data.cases.sort((a, b) => {
            if (a.transaction_ym < b.transaction_ym) {
                return -1;
            }
            return 0;
        });
    }

    return response.data.cases;
};
