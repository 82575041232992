import React, { useEffect, useState, useMemo } from 'react';
import { loadCSS } from 'fg-loadcss';
// import { usePosition } from '../components/common/usePosition';

import MapContainer from '../containers/MapContainer';
import Loading from '../components/common/Loading';

function MapPage() {
    const centerLocalStorage = JSON.parse(
        window.localStorage.getItem('last_center'),
    );

    const [latitude, longitude] = centerLocalStorage
        ? [...centerLocalStorage]
        : [37.51707472474788, 127.04128145824413]; // 강남구청역 좌표

    const [center] = useState({
        latitude,
        longitude,
        level: 3,
        init: true,
    });

    useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);

    const loading = useMemo(() => {
        if (center.latitude && center.latitude) {
            return false;
        } else {
            return true;
        }
    }, [center]);

    return <>{loading ? <Loading /> : <MapContainer initCenter={center} />}</>;
}

export default MapPage;
